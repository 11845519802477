import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function SelectAutoCompleteField(props) {
  const [selectedValue, setSelectedValue] = useState(props.value);

  // Update the selectedValue when props.value changes
  useEffect(() => {
    setSelectedValue(props.value || null);
  }, [props.value]);

// console.log("props.filters", props.filters);
  return (
    <Autocomplete
      key={props.filters ? Object.keys(props.filters).length : "props.label"}
      disablePortal
      id={props.label}
      options={props.data}
      value={selectedValue} // Use selectedValue as the value
      renderInput={(params) => <TextField {...params} label={props.label} />}
      onChange={(event, newValue) => {
        setSelectedValue(newValue); // Update selectedValue when a new value is selected
        props.handleFilterSelect({ target: { value: newValue, name: props.name } });
      }}
    />
  );
}

export default SelectAutoCompleteField;
