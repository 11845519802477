import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

function DeleteWarningPopup(props) {
  return (
    <Dialog open={true}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          Are you sure want to delete?
        </DialogContent>
        <DialogActions style={{display: "flex", justifyContent: "center"}}>
          <Button variant="outlined" style={{border: "1px solid rgba(25, 118, 210, 0.5)", color: "#1976d2"}} onClick={props.handleCancel}>Cancel</Button>
          <Button variant='contained' style={{backgroundColor: "#1976d2"}} onClick={props.delete} >Delete</Button>
        </DialogActions>
      </Dialog>
  )
}

export default DeleteWarningPopup