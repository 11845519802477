import React from 'react';
import ImageUploading from 'react-images-uploading';
import Button from '@mui/material/Button';

function ImageUploader() {
  const [images, setImages] = React.useState([]);
  const [coverImg, setCoverImg] = React.useState();

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const imageHandler = (imageList) => {
    setCoverImg(imageList[imageList.length-1].data_url);
  }

  return (
    <div className="App">
      {/* <ImageUploading multiple={true} maxNumber={10000} value = {coverImg} onImageUpload={(image) => {console.log("image*****", image)}} onChange = {(image) => imageHandler(image)} dataURLKey = 'data_url' onError={(error,files) => console.log("error",error, files)}>
        {({
        imageList,
        onImageUpload,
        }) => (
            <Button buttonTitle="Change cover" width={130} height={35} 
            backgroundColor="transparent" fontSize={15} color="white" onClick={onImageUpload}/>
            )}
        </ImageUploading> */}
    </div>
  );
}

export default ImageUploader;