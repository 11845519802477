import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InventoryIcon from '@mui/icons-material/Inventory';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LogoutIcon from '@mui/icons-material/Logout';
// import { useAuthenticated, useAuthProvider } from 'react-admin';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import MessageIcon from '@mui/icons-material/Message';
import axios from 'axios';
import DataGrid from "./DataGrid"
import moment from 'moment';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import UserList from "../User/List.js"
import FiltersPopup from "./FiltersPopup"
import WhatsAppPopup from '../User/WhatsApp';
import { useState } from 'react';
import WhatsAppMessageResultsDialoge from '../User/WhatsAppMessageResultsDialoge';
import Home from '../../Home';
import { url } from '../../constant';
import DefaultData from './DefaultData';
import axiosInstance from '../../http-common';
import SideDrawer from './SideDrawer';

const drawerWidth = 240;
let showUserList = false
let showDefault = false

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: "transparent"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "transparent"
});


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
    backgroundColor: "#121a4e", color: "#fff"
  }),
);

export default function MiniDrawer() {
    // useAuthenticated();
    // const authprovider = useAuthProvider()
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [filtersOpen, setFiltersOpen] = React.useState(false)
  const [res, setRes] = React.useState(null);
  const [isDataLoading, setIsDataLoading] = React.useState(true)
  const [filters, setFilters] = React.useState({})
  const navigate = useNavigate();
  const [showWhatsApp,  setShowWhatsApp] = useState(false)
  const [showAddImage, setShowAddImage] = useState(false)
  const [showDataGrid, setShowDataGrid] = useState(false)
  const [whatsAppMessageResults, setWhatsAppMessageResults] = useState(false)
  const [isSendInProgress, setSendInProgress] = React.useState(false)

  const [datesRange, setDatesRange] = React.useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  const handleSend = (numberLs, data, imageFile, additionalTexts) => {

    let formData = new FormData()
    imageFile && formData.append("image", imageFile, imageFile.name);
    formData.append("data", JSON.stringify(data))
    additionalTexts && formData.append("free_field_msg", additionalTexts.replace(/\n/g, '\\n'))

    var longArray = numberLs;
    var size = 100;
    var count = 0;
    var result = {}

    var newArray = new Array(Math.ceil(longArray.length / size)).fill("")
        .map(function() { return this.splice(0, size) }, longArray.slice());

    newArray?.forEach(arr => {
      formData.delete("phone_numbers")
      formData.append("phone_numbers", JSON.stringify(arr))

      setSendInProgress(true)
      axios.post(`${url}/simistocks/simi_whatsapp`,
      formData,
      // {"phone_numbers":numberLs}, 
      {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}`}})
      .then(res => {
        count++
        result = {...result, ...res.data}
        if(count === newArray.length){
          setWhatsAppMessageResults(result)
        }
        setSendInProgress(false)
      })
      .catch(error => {console.log(error.message);setSendInProgress(false);})
    })
    setTimeout(() => {
      // setWhatsAppMessageResults(result)
    }, 1000)
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleLogout = () => {
      // authprovider.logout(window.location.reload())
  }

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if(localStorage.getItem("login"))
    setIsDataLoading(true)
    axiosInstance.get(`${url}/simistocks/simidata`, {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}`}}).then((res) => {
        setRes(res.data)
        setIsDataLoading(false)
    }).catch(e=>{console.log(e);setIsDataLoading(false)})
  }, [])

  const getDoubleDigit = d => d <=9 ? `0${d}` : d

  const getFormattedDate = date => {

    let dateArray = date.split("-")
    let d = dateArray[0]
    let m = dateArray[1]
    let y = dateArray[2]
    return `${y}-${getDoubleDigit(m)}-${getDoubleDigit(d)}`
  }
  const filteredList = (tableData) => {
    let filteredList = tableData
    if(datesRange[0].startDate === null){
      if(!filters.godown && !filters.stockItem && !filters.stockGroup && !filters.category && !filters.financeName && !filters.brand && !filters.searchQuery && !filters.totalRange) 
        return tableData
    }

    if(filters.godown) filteredList = filteredList.filter((stock) => stock.K9 === filters.godown)

    if(filters.stockItem) filteredList = filteredList.filter((stock) => stock.K6 === filters.stockItem)

    if(filters.stockGroup) filteredList = filteredList.filter((stock) => stock.K7 === filters.stockGroup)

    if(filters.category) filteredList = filteredList.filter((stock) => stock.K15 === filters.category)

    if(filters.financeName) filteredList = filteredList.filter((stock) => stock.K12 === filters.financeName)

    if(filters.brand) filteredList = filteredList.filter((stock) => stock.K13 === filters.brand)

    if(filters.searchQuery) filteredList = filteredList.filter((stock) => JSON.stringify(stock).toLowerCase().includes(filters.searchQuery.toLowerCase()))

    if(filters.totalRange) {
      if(filters.totalRange.min  && filters.totalRange.max) filteredList = filteredList.filter((stock) => parseFloat(stock.K11) > parseFloat(filters.totalRange.min) && parseFloat(stock.K11) < parseFloat(filters.totalRange.max))
      else if(filters.totalRange.min && !filters.totalRange.max) filteredList = filteredList.filter((stock) => parseFloat(stock.K11) > parseFloat(filters.totalRange.min))
      else if(!filters.totalRange.min && filters.totalRange.max) filteredList = filteredList.filter((stock) => parseFloat(stock.K11) < parseFloat(filters.totalRange.max))
    }

    if(datesRange[0].startDate === null) return filteredList

      let ed, sd, result
      ed = datesRange[0].endDate ? moment(datesRange[0].endDate, "YYYY-MM-DD") : moment().format("YYYY-MM-DD")
      sd = datesRange[0].startDate ? moment(datesRange[0].startDate, "YYYY-MM-DD") : moment().format("YYYY-MM-DD")
      // sd = moment(datesRange[0].startDate, "YYYY-MM-DD");
      filteredList = filteredList && filteredList.filter(d => {
                            var time = moment(getFormattedDate(d.K1), "YYYY-MM-DD");
                            if(!isNaN(time)){
                              return time.isBetween(sd,ed, undefined, '[]')
                            } else {
                              return false
                            }
                            });

      return filteredList
  }
  return (
    <Box>
      {/* <CssBaseline /> */}
      <SideDrawer drawer={
          <>
          <List >
            <div  to={"stocks"}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => {showUserList=false;setShowWhatsApp(false)}}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: "#fff"
                  }}
                >
                  <InventoryIcon sx={{color: "#fff"}} />
                </ListItemIcon>
                <ListItemText primary={"Stocks"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} />
              </ListItemButton>
            </div>
            <div  to={"/WhatsApp"}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => {showUserList=false;setShowWhatsApp(true)}}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: "#fff"
                  }}
                >
                  <WhatsAppIcon sx={{color: "#fff"}} />
                </ListItemIcon>
                <ListItemText primary={"WhatsApp"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} />
              </ListItemButton>
            </div>
            <div  to={"/WhatsApp"}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => {showUserList=false;showDefault=true;setShowWhatsApp(true)}}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: "#fff"
                  }}
                >
                  <MessageIcon sx={{color: "#fff"}} />
                </ListItemIcon>
                <ListItemText primary={"WhatsApp"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} />
              </ListItemButton>
            </div>
            <div  to={"/AddImage"}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => {showUserList=false;setShowAddImage(true)}}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: "#fff"
                  }}
                >
                  <AddPhotoAlternateIcon sx={{color: "#fff"}} />
                </ListItemIcon>
                <ListItemText primary={"WhatsApp"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} />
              </ListItemButton>
            </div>
            {JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).admin && <div  to={"/userCreate"}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => {showUserList=true;setShowWhatsApp(false)}}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: "#fff"
                  }}
                >
                  <GroupAddIcon sx={{color: "#fff"}} />
                </ListItemIcon>
                <ListItemText primary={"Stocks"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} />
              </ListItemButton>
            </div>}
          </List>
          <Divider />
          <List >
            <ListItem key={"Logout"} onClick={handleLogout} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LogoutIcon sx={{color: "#fff"}} />
                </ListItemIcon>
                <ListItemText primary={"Logout"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} />
              </ListItemButton>
            </ListItem>
          </List>
          </> 
        } content={
            <Box component="main">
              <DrawerHeader />
              {!showDataGrid && !showUserList && !showWhatsApp && !showAddImage && <Home setShowDataGrid={setShowDataGrid} />}
              { !showUserList && !showWhatsApp && !showAddImage && showDataGrid &&
                <DataGrid 
                  setRes={setRes}
                  filters={filters}
                  setFilters={setFilters}
                  clearFilter={() => {setFilters({});setDatesRange([{startDate: null, endDate: null, key:'selection'}])}}
                  setFiltersOpen={setFiltersOpen}
                  isDataLoading={isDataLoading}
                  res={filteredList(res)}
                  setDatesRange={setDatesRange}
                  datesRange={datesRange}/>
              }
              {showUserList && <UserList />}
              {showWhatsApp && <WhatsAppPopup showDefault={showDefault} isSendInProgress={isSendInProgress} showInputField={true} handleCancel={() => {setShowWhatsApp(false);showDefault=false}} handleSend={handleSend}  />}
              {showAddImage && <DefaultData handleCancel={() => {setShowAddImage(false)}}/>}
              {
                res && <FiltersPopup filtersOpen={filtersOpen} filters={filters} setFilters={setFilters} setFiltersOpen={setFiltersOpen} apiData={res} />
              }

            {
              whatsAppMessageResults && <WhatsAppMessageResultsDialoge handleDone={() => {setWhatsAppMessageResults(null)}} whatsAppMessageResults={whatsAppMessageResults} />
            }
            </Box>
          } />
    </Box>
  );
}

{/* <Drawer variant="permanent" open={open}>
        <DrawerHeader >
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 0.5,
              ...(open && { display: 'none' }),
              color: "#fff"
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton sx={{
              ...(!open && { display: 'none' }),
            }} onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon sx={{color: "#fff"}} /> : <ChevronLeftIcon sx={{color: "#fff"}} />}
          </IconButton>
        </DrawerHeader>
        <Divider/>
        <List >
          <div  to={"stocks"}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {showUserList=false;setShowWhatsApp(false)}}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: "#fff"
                }}
              >
                <InventoryIcon sx={{color: "#fff"}} />
              </ListItemIcon>
              <ListItemText primary={"Stocks"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} />
            </ListItemButton>
          </div>
          <div  to={"/WhatsApp"}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {showUserList=false;setShowWhatsApp(true)}}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: "#fff"
                }}
              >
                <WhatsAppIcon sx={{color: "#fff"}} />
              </ListItemIcon>
              <ListItemText primary={"WhatsApp"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} />
            </ListItemButton>
          </div>
          <div  to={"/WhatsApp"}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {showUserList=false;showDefault=true;setShowWhatsApp(true)}}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: "#fff"
                }}
              >
                <MessageIcon sx={{color: "#fff"}} />
              </ListItemIcon>
              <ListItemText primary={"WhatsApp"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} />
            </ListItemButton>
          </div>
          <div  to={"/AddImage"}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {showUserList=false;setShowAddImage(true)}}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: "#fff"
                }}
              >
                <AddPhotoAlternateIcon sx={{color: "#fff"}} />
              </ListItemIcon>
              <ListItemText primary={"WhatsApp"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} />
            </ListItemButton>
          </div>
          {JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).admin && <div  to={"/userCreate"}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {showUserList=true;setShowWhatsApp(false)}}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: "#fff"
                }}
              >
                <GroupAddIcon sx={{color: "#fff"}} />
              </ListItemIcon>
              <ListItemText primary={"Stocks"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} />
            </ListItemButton>
          </div>}
        </List>
        <Divider />
        <List >
          <ListItem key={"Logout"} onClick={handleLogout} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <LogoutIcon sx={{color: "#fff"}} />
              </ListItemIcon>
              <ListItemText primary={"Logout"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer> */}
