import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function BasicCard(props) {
  return (
    <Card sx={{ minWidth: 300, height: "max-content", minHeight: "max-content", boxShadow: "0px 0px 0px" }}>
      <CardContent style={{textAlign: "center", padding: "0px"}}>
        <Grid container gap={"1.5rem"} pb={3} justifyContent={"center"}>
          <Grid item container pt={2} pb={2} pl={2} bgcolor={"#f3f3f3"}>
            <Grid item xs={8} display={"flex"} alignItems={"center"}>
              <CalendarMonthIcon sx={{p: 0}} />
              <Typography width={"100%"} fontWeight={"bold"} color="text.secondary">
                {props.data.J3} To {props.data.J4}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button variant={"contained"}>
                ₹ {props.data.J5}
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={11} gap={"1rem"}>
            <Grid item xs={12}>
              <Box border="1px solid #898989" borderRadius="5px" padding="5px">
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {props.data.J2}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box border="1px solid #898989" borderRadius="5px" padding="5px">
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {props.data.J6}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
