import React, { useEffect, useRef, useState } from 'react';
import { Typography, Grid, IconButton } from '@mui/material';
import axiosInstance from '../../http-common'; // Assuming axiosInstance is pre-configured for authenticated requests
import moment from 'moment';
import { CheckRounded, ContentCopyOutlined, Done, DoneAll } from '@mui/icons-material';

function Message({ message }) {
    const [isCopied, setIsCopied] = useState(false)

    const handleCopy = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(message?.message)
        
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 2000)
    }
    return (
        <Grid container item xs={12} className='wa-message' sx={{ p: 0 }}
            alignContent={"baseline"}
        // justifyContent={message.message_type === "received" ? "start" : "end"}
        >
            <Grid item width={"90%"}>
                <Typography
                    sx={message.message_type !== "received" ? { float: "right", position: "relative", pr: "40px" } : { float: "left" }}
                    align={message.message_type === "received" ? "left" : "left"}
                    className={message.message_type === "received" ? "left-msg msg" : "right-msg msg"}
                    width={"100%"}
                >
                    {(message?.image || message?.video || message?.document) && (
                        <div style={{ marginTop: '10px' }}>
                            {message?.image && (
                                <img
                                    src={message?.image}
                                    alt="attachment"
                                    style={{ width: 'calc(100% + 30px)', marginBottom: '10px' }}
                                />
                            )}
                            {message?.video && (
                                <video
                                    controls
                                    src={message?.video}
                                    style={{ width: 'calc(100% + 30px)', marginBottom: '10px' }}
                                />
                            )}
                            {message?.document && (

                                // <DownloadButton fileUrl={message?.document} />
                                <p
                                    // href={message?.document}
                                    // download
                                    style={{ width: 'calc(100% + 30px)', marginBottom: '10px' }}
                                >
                                    Document
                                </p>
                            )}
                            {/* <a
                                                    href={e.file}
                                                    download
                                                    style={{ display: 'block', textDecoration: 'underline', color: 'blue' }}
                                                >
                                                    Download Attachment
                                                </a> */}
                        </div>
                    )}

                    <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: message?.message?.replaceAll("\\n", "<br/>") }}></span>
                    <span style={{ position: "absolute", bottom: 0, right: 10 }}>
                        {message.status === "delivered" ?
                            <DoneAll fontSize={"small"} />
                            : message.status === "read" ?
                                <DoneAll color={"primary"} fontSize={"small"} />
                                : <Done fontSize={"small"} />
                        }
                    </span>
                </Typography>
            </Grid>
            <Grid item width={"10%"}>
                <IconButton sx={{p: "8px !important"}} onClick={handleCopy}>
                    {isCopied ? <CheckRounded /> : <ContentCopyOutlined />}
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    // align={message.message_type === "received" ? "left" : "right"}
                    className={"time-txt"}
                >
                    {moment(message.created).format("DD MMM YY | hh:mm A")}
                </Typography>
            </Grid>
        </Grid>
    )
}

const MessagesTable = () => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView();
    }

    // Fetch messages from the API
    const fetchMessages = async (fcmToken) => {
        try {
            const url = fcmToken ? `/simistocks/list_api_messages?fcmtoken=${fcmToken}` : '/simistocks/list_api_messages?fcmtoken=dCu71r-jT8-Ed2gnVGsWmz:APA91bGYoK9r5Hxw0jcL0hAKIUXRZN0vTScoRVO_PPrRXU1drAsfoPLyNydlhAyR3GZCn-NCH3Cq9dRUO5UlSlkH8FH07-8PB1BF0f4vrDVUjPm2hicnJRJSgv-73fqCx3wo0oFICGfj'
            const response = await axiosInstance.get(url);
            setMessages(response.data);  // Set messages from the API response
            setLoading(false);           // Set loading to false after data is fetched
            setTimeout(() => scrollToBottom(),);
        } catch (err) {
            setError("Failed to load messages.");
            setLoading(false);
        }
    };

    // Load data on component mount
    useEffect(() => {
        if (window.FirebasePlugin) {
            window.FirebasePlugin.getToken(token => {
                fetchMessages(token)
            }, error => console.log("FCM Token Error", error))
        } else {
            fetchMessages();
        }
    }, []);

    return (
        <div style={{ padding: '1rem' }}>
            <Typography variant="h5" gutterBottom>
                Messages List
            </Typography>

            {/* Loading or error state */}
            {loading ? (
                <Typography>Loading messages...</Typography>
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                <Grid container xs={12} gap={"1rem"} maxHeight={"calc(100vh - 150px)"} sx={{overflowY: "auto", overflowX: "hidden"}}>
                    {
                        messages.length > 0 ? (
                            <>
                                {messages.map((msg, index) => (
                                    // <TableRow key={index}>
                                    <Message
                                        message={msg}
                                    />
                                ))}
                                <div ref={messagesEndRef} />
                            </>
                        ): <Typography>No records found for the user</Typography>}
                </Grid>
            )}
        </div>
    );
};

export default MessagesTable;
