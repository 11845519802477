import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/auth';
import layoutReducer from '../slices/layout';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    layout: layoutReducer
  },
});

