import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import './index.css';
import { HashRouter } from 'react-router-dom';

const container = document.getElementById('root');
const root = createRoot(container);

if(window.cordova){
  document.addEventListener('deviceready', 

  () => {
    root.render(
        <Provider store={store}>
          <HashRouter>
            <App />
          </HashRouter>
        </Provider>
    );
  }

  , false);
} else {
  root.render(
      <Provider store={store}>
        <HashRouter>
          <App />
        </HashRouter>
      </Provider>
  );
}