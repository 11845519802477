import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  CssBaseline,
  DialogActions,
  DialogContent,
  Drawer,
  Hidden,
  IconButton,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import { url } from '../constant';
import axios from 'axios';
import { Box, Button, Container, Dialog, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MessageIcon from '@mui/icons-material/Message';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Home from '../Home';

import DataGrid from "../Components/UIComponents/DataGrid"
import DataGrid2 from "../Components/UIComponents/DataGrid2"
import DataGrid2Web from "../Components/UIComponents/DataGrid2Web"
import moment from 'moment';
import UserList from "../Components/User/List.js"
import UserApprovalList from "../Components/User/UserApprovalList.js"
import simiInfoIcon from "../Components/UIComponents/simi-info-icon.png"
import FiltersPopup from "../Components/UIComponents/FiltersPopup"
import SRFiltersPopup from "../Components/UIComponents/SRFiltersPopup"
import WhatsAppPopup from '../Components/User/WhatsApp';
import WhatsAppMessageResultsDialoge from '../Components/User/WhatsAppMessageResultsDialoge';
import DefaultData from '../Components/UIComponents/DefaultData';
import StockList from './UIComponents/StockList';
import { forbidden, getSalesData, getStocksData, logOut } from '../slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AppBlockingIcon from '@mui/icons-material/AppBlocking';
import BlockNumbers from './User/BlockNumbers.js';
import WhatsappChat from './User/WhatsappChat.js';
import { Campaign, HomeOutlined, HomeRounded, ListAlt, Summarize, TableBar } from '@mui/icons-material';
import WhatsappChatSummary from './User/WhatsappChatSummary.js';
import MessagesTable from './User/MessagesTable.js';
import { setShowWhatsAppChat } from '../slices/layout.js';

const drawerWidth = 60;
// let showUserList = false
const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex'
  },
  drawer: {
    position: "sticky",
    width: "100%",
    top: 0,
    backgroundColor: "#d4f4fb",
    zIndex: 9,
    height: "max-content",
    [theme.breakpoints.up('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    // backgroundColor: "#121a4e",
    backgroundColor: "#fff",
    overflow: "hidden"
  },
  menuButton: {
    position: "absolute"
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  homeButton: {
    position: "absolute",
    top: "15px",
    right: "10px"
  },
  toolbar: theme.mixins.toolbar,
}));

const Layout = ({ window }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [srFiltersOpen, setSRFiltersOpen] = React.useState(false)
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const open = false;
  const [filtersOpen, setFiltersOpen] = React.useState(false)
  const [showSRGrid, setShowSRGrid] = useState(false)
  const [res, setRes] = React.useState(null);
  const [stockReportRes, setStockReportRes] = React.useState(null);
  const [isDataLoading, setIsDataLoading] = React.useState(true)
  const [filters, setFilters] = React.useState({})
  const [srFilters, setSRFilters] = React.useState({})
  const dispatch = useDispatch()
  const [showWhatsApp, setShowWhatsApp] = useState(false)
  // const [showWhatsAppChat, setShowWhatsAppChat] = useState(false)
  const [showSummary, setShowSummary] = useState(false)
  const [showMessagesTable, setShowMessagesTable] = useState(false)
  const [showBlocked, setShowBlocked] = useState(false)
  const [showUserList, setShowUserList] = useState(false)
  const [showUserApprovalList, setShowUserApprovalList] = useState(false)
  const [showAddImage, setShowAddImage] = useState(false)
  const [showDataGrid, setShowDataGrid] = useState(false)
  const [showLogout, setLogoutPopup] = useState(false)
  const [whatsAppMessageResults, setWhatsAppMessageResults] = useState(false)
  const [isSendInProgress, setSendInProgress] = React.useState(false)
  const [showDefault, setShowDefault] = React.useState(false)

  const { showWhatsAppChat } = useSelector(state => state.layout)

  const [datesRange, setDatesRange] = React.useState([
    {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      key: 'selection'
    }
  ]);

  const [srDatesRange, setSRDatesRange] = React.useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  const [offersDatesRange, setOffersDateRange] = React.useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  const handleSend = (numberLs, data, imageFile, additionalTexts) => {

    let formData = new FormData()
    imageFile && formData.append("image", imageFile, imageFile.name);
    formData.append("data", JSON.stringify(data))
    additionalTexts && formData.append("free_field_msg", additionalTexts.replace(/\n/g, '\\n'))

    var longArray = numberLs;
    var size = 100;
    var count = 0;
    var result = {}

    var newArray = new Array(Math.ceil(longArray.length / size)).fill("")
      .map(function () { return this.splice(0, size) }, longArray.slice());

    newArray?.forEach(arr => {
      formData.delete("phone_numbers")
      formData.append("phone_numbers", JSON.stringify(arr))

      setSendInProgress(true)
      axios.post(`${url}/simistocks/simi_whatsapp`,
        formData,
        // {"phone_numbers":numberLs}, 
        { headers: { "Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}` } })
        .then(res => {
          count++
          result = { ...result, ...res.data }
          if (count === newArray.length) {
            setWhatsAppMessageResults(result)
            setSendInProgress(false)
          }
        })
        .catch(error => {
          console.log(error.message);
          setSendInProgress(false);
        })
    })
    setTimeout(() => {
      // setWhatsAppMessageResults(result)
    }, 1000)
  }

  const handleLogout = () => {
    // console.log("window?.navigator?.notification", window?.navigator?.notification);
    if (window?.navigator?.notification)
      window.navigator.notification?.confirm(
        "Do you want to logout?",
        onConfirmLogout,
        "Logout!",
        ["Yes", "No"]
      )
    else {
      // dispatch(logOut())
      setLogoutPopup(true)
    }
  }

  function onConfirmLogout(buttonIndex) {
    if (buttonIndex === 1) {
      dispatch(logOut())
    }
  }

  useEffect(() => {
    if (localStorage.getItem("forbidden")) {
      dispatch(forbidden())
      return
    }

    setIsDataLoading(true)
    dispatch(getSalesData()).then((res) => {
      // console.log("res.status", res);
      if (res.meta.requestStatus === "fulfilled")
        setRes(res.payload)
      setIsDataLoading(false)
    }).catch(e => { console.log(e); setIsDataLoading(false) })
    dispatch(getStocksData()).then((res) => {
      if (res.meta.requestStatus === "fulfilled")
        setStockReportRes(res.payload)
      setIsDataLoading(false)
    }).catch(e => { console.log(e); setIsDataLoading(false) })
  }, [])

  const getDoubleDigit = d => d <= 9 ? `0${d}` : d

  const getFormattedDate = date => {

    let dateArray = date.split("-")
    let d = dateArray[0]
    let m = dateArray[1]
    let y = dateArray[2]
    return `${y}-${getDoubleDigit(m)}-${getDoubleDigit(d)}`
  }
  const filteredList = (tableData) => {
    let filteredList = tableData

    if (datesRange[0].startDate === null) {
      if (!filters.godown && !filters.stockItem && !filters.stockGroup && !filters.category && !filters.financeName && !filters.brand && !filters.searchQuery && !filters.totalRange
        && filters["K1"] && !filters["K2"] && !filters["K3"] && !filters["K4"] && !filters["K5"] && !filters["K6"] && !filters["K7"] && !filters["K8"] && !filters["K9"]
        && !filters["K10"] && !filters["K11"] && !filters["K12"] && !filters["K13"] && !filters["K14"] && !filters["K15"] && !filters["K16"])
        return tableData
    }

    if (
      filters["K1"]
      || filters["K2"]
      || filters["K3"]
      || filters["K4"]
      || filters["K5"]
      || filters["K6"]
      || filters["K7"]
      || filters["K8"]
      || filters["K9"]
      || filters["K10"]
      || filters["K11"]
      || filters["K12"]
      || filters["K13"]
      || filters["K14"]
      || filters["K15"]
      || filters["K16"]
    ) {
      let filtersKeys = Object.keys(filters)
      let onlyStockeys = filtersKeys.filter(k => k.slice(0, 1) === "K")
      onlyStockeys?.forEach(key => {
        // console.log("onlyStockeys", onlyStockeys, key);
        filteredList = filteredList.filter((stock) => stock[`${key}`]?.toString().toLowerCase().includes(filters[`${key}`]?.toString().toLowerCase()))
      })
    }

    if (filters.godown) filteredList = filteredList.filter((stock) => stock.K9 === filters.godown)

    if (filters.stockItem) filteredList = filteredList.filter((stock) => stock.K6 === filters.stockItem)

    if (filters.stockGroup) filteredList = filteredList.filter((stock) => stock.K7 === filters.stockGroup)

    if (filters.category) filteredList = filteredList.filter((stock) => stock.K15 === filters.category)

    if (filters.financeName) filteredList = filteredList.filter((stock) => stock.K12 === filters.financeName)

    if (filters.brand) filteredList = filteredList.filter((stock) => stock.K13 === filters.brand)

    if (filters.searchQuery) filteredList = filteredList.filter((stock) => JSON.stringify(stock).toLowerCase().includes(filters.searchQuery.toLowerCase()))

    if (filters.totalRange) {
      if (filters.totalRange.min && filters.totalRange.max) filteredList = filteredList.filter((stock) => parseFloat(stock.K11) > parseFloat(filters.totalRange.min) && parseFloat(stock.K11) < parseFloat(filters.totalRange.max))
      else if (filters.totalRange.min && !filters.totalRange.max) filteredList = filteredList.filter((stock) => parseFloat(stock.K11) > parseFloat(filters.totalRange.min))
      else if (!filters.totalRange.min && filters.totalRange.max) filteredList = filteredList.filter((stock) => parseFloat(stock.K11) < parseFloat(filters.totalRange.max))
    }

    if (datesRange[0].startDate === null) return filteredList

    let ed, sd;
    ed = datesRange[0].endDate ? moment(datesRange[0].endDate, "YYYY-MM-DD") : moment().format("YYYY-MM-DD")
    sd = datesRange[0].startDate ? moment(datesRange[0].startDate, "YYYY-MM-DD") : moment().format("YYYY-MM-DD")
    // sd = moment(datesRange[0].startDate, "YYYY-MM-DD");
    filteredList = filteredList && filteredList.filter(d => {
      var time = moment(getFormattedDate(d.K1), "YYYY-MM-DD");
      if (!isNaN(time)) {
        return time.isBetween(sd, ed, undefined, '[]')
      } else {
        return false
      }
    });

    return filteredList
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const srFilteredList = (tableData) => {
    let filteredList = tableData

    if (!filteredList) return
    // if(srDatesRange[0].startDate === null){
    //   if(!srFilters.godown && !srFilters.stockItem && !srFilters.stockGroup && !srFilters.category && !srFilters.financeName && !srFilters.brand && !srFilters.searchQuery && !srFilters.totalRange) 
    //     return tableData
    // }

    if (srFilters.fileName) filteredList = filteredList.filter((stock) => stock.a1 === srFilters.fileName)

    if (srFilters.stockItem) filteredList = filteredList.filter((stock) => stock.b1 === srFilters.stockItem)

    if (srFilters.stockGroup) filteredList = filteredList.filter((stock) => stock.c1 === srFilters.stockGroup)

    if (srFilters.shopList) filteredList = filteredList.filter((stock) => stock.d1 === srFilters.shopList)

    if (srFilters.searchQuery) filteredList = filteredList.filter((stock) => JSON.stringify(stock).toLowerCase().includes(srFilters.searchQuery.toLowerCase()))

    if (srFilters.showOffersItems) filteredList = filteredList.filter((stock) => stock.schemes.length > 0)

    // if(srFilters.totalRange) {
    //   if(srFilters.totalRange.min  && srFilters.totalRange.max) filteredList = filteredList.filter((stock) => parseFloat(stock.K11) > parseFloat(filters.totalRange.min) && parseFloat(stock.K11) < parseFloat(filters.totalRange.max))
    //   else if(srFilters.totalRange.min && !srFilters.totalRange.max) filteredList = filteredList.filter((stock) => parseFloat(stock.K11) > parseFloat(filters.totalRange.min))
    //   else if(!srFilters.totalRange.min && srFilters.totalRange.max) filteredList = filteredList.filter((stock) => parseFloat(stock.K11) < parseFloat(filters.totalRange.max))
    // }

    if (offersDatesRange[0].startDate === null) return filteredList;

    const ed = offersDatesRange[0].endDate ? moment(offersDatesRange[0].endDate, "YYYY-MM-DD") : moment().format("YYYY-MM-DD");
    const sd = offersDatesRange[0].startDate ? moment(offersDatesRange[0].startDate, "YYYY-MM-DD") : moment().format("YYYY-MM-DD");

    filteredList = filteredList.filter(d => {
      const schemeTimes = [
        ...d.schemes.map(s => moment(getFormattedDate(s.J3), "YYYY-MM-DD")),
        ...d.schemes.map(s => moment(getFormattedDate(s.J4), "YYYY-MM-DD"))
      ];

      return schemeTimes.some(time => !isNaN(time) && time.isBetween(sd, ed, undefined, '[]'));
    });


    return filteredList
  }

  // useEffect(() => {
  //   const textElements = document.querySelectorAll('p, span, div, h1, h2, h3, h4, h5, h6, html, body, table, form, input, th, td, tr, svg');

  //   textElements?.forEach(element => {
  //     element.style = "color: #fff !important; background-color: #000 !important;"
  // });
  // },[])

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const access_allowed = JSON.parse(localStorage.getItem("login"))?.access_allowed
  const adminUser = JSON.parse(localStorage.getItem("login")) ? JSON.parse(localStorage.getItem("login")).admin : false

  const drawer = (<>

    <List >
      {(adminUser || access_allowed?.sales) && <div>
        <ListItemButton
          sx={{
            minHeight: 48, padding: "8px 20px !important",
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
          onClick={() => {
            setShowDataGrid(true);
            setShowUserList(false);
            setShowUserApprovalList(false);
            setMobileOpen(false);
            setShowWhatsApp(false);
            dispatch(setShowWhatsAppChat(false))
            setShowSummary(false);
            setShowMessagesTable(false);
            setShowBlocked(false);
            setShowSRGrid(false);
            setShowAddImage(false)
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: "#fff"
            }}
          >
            <ReceiptLongOutlinedIcon sx={{ color: "#000" }} />
            {/* <img src={stocksIcon} height={"1rem"} alt="stocks"/> */}

          </ListItemIcon>
          {/* <ListItemText primary={"Stocks"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} /> */}
        </ListItemButton>
      </div>}
      {(adminUser || access_allowed?.stock) && <div>
        <ListItemButton
          sx={{
            minHeight: 48, padding: "8px 20px !important",
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
          onClick={() => {
            setShowSRGrid(true);
            setShowDataGrid(false);
            setShowUserList(false);
            setShowUserApprovalList(false);
            setMobileOpen(false);
            setShowWhatsApp(false);
            dispatch(setShowWhatsAppChat(false))
            setShowBlocked(false);
            setShowAddImage(false)
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: "#fff"
            }}
          >
            <AccountTreeIcon sx={{ color: "#000", "&:before": { content: "'₹'" } }} />
            {/* <img src={salesIcon} height={"30px"} alt="sales"/> */}
          </ListItemIcon>
          <ListItemText primary={"Stocks"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} />
        </ListItemButton>
      </div>}
      {(adminUser || access_allowed?.whatsAppBulk) && <div>
        <ListItemButton
          sx={{
            minHeight: 48,
            padding: "8px 20px !important",
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
          onClick={() => {
            setShowWhatsApp(true);
            dispatch(setShowWhatsAppChat(false))
            setShowSummary(false);
            setShowMessagesTable(false);
            setShowBlocked(false);
            setShowDefault(false);
            setShowSRGrid(false);
            setShowDataGrid(false);
            setShowUserList(false);
            setMobileOpen(false);
            setShowAddImage(false)
            setShowUserApprovalList(false);
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: "#fff"
            }}
          >
            <Campaign sx={{ color: "#000" }} />
          </ListItemIcon>
          {/* <ListItemText primary={"WhatsApp"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} /> */}
        </ListItemButton>
      </div>}
      {(adminUser || access_allowed?.whatsAppChat) && <div>
        <ListItemButton
          sx={{
            minHeight: 48,
            padding: "8px 20px !important",
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
          onClick={() => {
            setShowWhatsApp(false);
            dispatch(setShowWhatsAppChat(true))
            setShowSummary(false);
            setShowMessagesTable(false);
            setShowBlocked(false);
            setShowDefault(false);
            setShowSRGrid(false);
            setShowDataGrid(false);
            setShowUserList(false);
            setMobileOpen(false);
            setShowAddImage(false)
            setShowUserApprovalList(false);
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: "#fff"
            }}
          >
            <WhatsAppIcon sx={{ color: "#000" }} />
          </ListItemIcon>
        </ListItemButton>
      </div>}
      {(adminUser || access_allowed?.whatsAppStatus) && <div>
        <ListItemButton
          sx={{
            minHeight: 48,
            padding: "8px 20px !important",
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
          onClick={() => {
            setShowWhatsApp(false);
            dispatch(setShowWhatsAppChat(false))
            setShowSummary(true);
            setShowMessagesTable(false);
            setShowBlocked(false);
            setShowDefault(false);
            setShowSRGrid(false);
            setShowDataGrid(false);
            setShowUserList(false);
            setMobileOpen(false);
            setShowAddImage(false)
            setShowUserApprovalList(false);
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: "#fff"
            }}
          >
            <Summarize sx={{ color: "#000" }} />
          </ListItemIcon>
        </ListItemButton>
      </div>}
      {/* {(adminUser || access_allowed?.whatsAppChat) && <div>
        <ListItemButton
          sx={{
            minHeight: 48,
            padding: "8px 20px !important",
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
          onClick={() => {
            setShowWhatsApp(false);
            dispatch(setShowWhatsAppChat(false))
            setShowSummary(false);
            setShowMessagesTable(true);
            setShowBlocked(false);
            setShowDefault(false);
            setShowSRGrid(false);
            setShowDataGrid(false);
            setShowUserList(false);
            setMobileOpen(false);
            setShowAddImage(false)
            setShowUserApprovalList(false);
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: "#fff"
            }}
          >
            <ListAlt sx={{ color: "#000" }} />
          </ListItemIcon>
        </ListItemButton>
      </div>} */}
      {(adminUser || access_allowed?.blockList) && <div>
        <ListItemButton
          sx={{
            minHeight: 48,
            padding: "8px 20px !important",
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
          onClick={() => {
            setShowWhatsApp(false);
            dispatch(setShowWhatsAppChat(false))
            setShowSummary(false);
            setShowMessagesTable(false);
            setShowBlocked(true);
            setShowDefault(false);
            setShowSRGrid(false);
            setShowDataGrid(false);
            setShowUserList(false);
            setMobileOpen(false);
            setShowAddImage(false)
            setShowUserApprovalList(false);
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: "#fff"
            }}
          >
            <AppBlockingIcon sx={{ color: "#000" }} />
          </ListItemIcon>
          {/* <ListItemText primary={"WhatsApp"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} /> */}
        </ListItemButton>
      </div>}
      {(adminUser || access_allowed?.quickWA) && <div>
        <ListItemButton
          sx={{
            minHeight: 48,
            padding: "8px 20px !important",
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
          onClick={() => {
            setShowDefault(true);
            setShowWhatsApp(true);
            setShowBlocked(false);
            dispatch(setShowWhatsAppChat(false))
            setShowSummary(false);
            setShowMessagesTable(false);
            setShowSRGrid(false);
            setShowDataGrid(false);
            setShowUserList(false);
            setMobileOpen(false);
            setShowAddImage(false)
            setShowUserApprovalList(false);
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: "#fff"
            }}
          >
            <MessageIcon sx={{ color: "#000" }} />
          </ListItemIcon>
          {/* <ListItemText primary={"WhatsApp"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} /> */}
        </ListItemButton>
      </div>}
      {(adminUser || access_allowed?.attachment) && <div to={"/AddImage"}>
        <ListItemButton
          sx={{
            minHeight: 48, padding: "8px 20px !important",
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
          onClick={() => {
            setShowAddImage(true)
            setShowWhatsApp(false);
            setShowBlocked(false);
            dispatch(setShowWhatsAppChat(false))
            setShowSummary(false);
            setShowMessagesTable(false);
            setShowSRGrid(false);
            setShowDataGrid(false);
            setShowUserList(false);
            setMobileOpen(false);
            setShowUserApprovalList(false);
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: "#fff"
            }}
          >
            <AddPhotoAlternateIcon sx={{ color: "#000" }} />
          </ListItemIcon>
          {/* <ListItemText primary={"WhatsApp"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} /> */}
        </ListItemButton>
      </div>}
      {adminUser && <div to={"/userCreate"}>
        <ListItemButton
          sx={{
            minHeight: 48, padding: "8px 20px !important",
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
          onClick={() => {
            setShowUserList(true);
            setShowAddImage(false)
            setShowWhatsApp(false);
            setShowBlocked(false);
            dispatch(setShowWhatsAppChat(false))
            setShowSummary(false);
            setShowMessagesTable(false);
            setShowSRGrid(false);
            setShowDataGrid(false);
            setMobileOpen(false);
            setShowUserApprovalList(false);
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: "#fff"
            }}
          >
            <GroupAddIcon sx={{ color: "#000" }} />
          </ListItemIcon>
          {/* <ListItemText primary={"Stocks"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} /> */}
        </ListItemButton>
      </div>}
      {(adminUser || access_allowed?.device) && <div to={"/userApprove"}>
        <ListItemButton
          sx={{
            minHeight: 48, padding: "8px 20px !important",
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
          onClick={() => {
            setShowUserApprovalList(true);
            setShowUserList(false);
            setShowAddImage(false)
            setShowWhatsApp(false);
            setShowBlocked(false);
            dispatch(setShowWhatsAppChat(false))
            setShowSummary(false);
            setShowMessagesTable(false);
            setShowSRGrid(false);
            setShowDataGrid(false);
            setMobileOpen(false);
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: "#fff"
            }}
          >
            <MobileFriendlyIcon sx={{ color: "#000" }} />
          </ListItemIcon>
          {/* <ListItemText primary={"Stocks"} sx={{ opacity: open ? 1 : 0, color: "#fff" }} /> */}
        </ListItemButton>
      </div>}
    </List>
    <Divider />
    <List >
      <ListItem key={"Logout"} onClick={handleLogout} disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          sx={{
            minHeight: 48, padding: "8px 20px !important",
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <LogoutIcon sx={{ color: "#000" }} />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    </List>

  </>)

  return (
    <div>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="sidebar">
        <Box padding={1.5}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <div className={classes.toolbar} style={{ minHeight: "max-content", display: "flex", justifyContent: "space-between", marginTop: "-20px", padding: "10px 25px", alignItems: "center" }}>
          <img src={simiInfoIcon} onClick={() => document.location.reload()} style={{ height: "52px", margin: "auto" }} alt="simi infotech" />
        </div>
        {/* <Box padding={0}> */}
          <IconButton
            color="inherit"
            onClick={() => document.location.reload()}
            className={classes.homeButton}
          >
            <HomeRounded />
          </IconButton>
        {/* </Box> */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {/* Add your main content here */}
        <Container maxWidth="xl" className='grid-container' sx={{ height: "90vh", backgroundColor: "#fff", padding: { xs: "0px !important", md: "10px 0px" }, paddingTop: "10px", maxWidth: "100% !important", margin: 0 }}>
          <Box maxWidth={{ xs: "100%", md: "96%" }} margin={{ xs: "0px", md: "auto" }} marginRight={{ md: "0px" }}>
            {!showDataGrid && !showUserList && !showUserApprovalList && !showWhatsApp && !showWhatsAppChat && !showBlocked && !showAddImage && !showSRGrid && !showSummary && !showMessagesTable && <Home setShowMessagesTable={setShowMessagesTable} setShowDataGrid={setShowSRGrid} />}
            {!showUserList && !showUserApprovalList && !showWhatsApp && !showAddImage && !showBlocked && showDataGrid && !showSRGrid &&
              (process.env.REACT_APP_DEVICETYPE === "nativeApp" ? <StockList
                setRes={setRes}
                filters={filters}
                setFilters={setFilters}
                clearFilter={() => { setFilters({}); setDatesRange([{ startDate: null, endDate: null, key: 'selection' }]) }}
                setFiltersOpen={setFiltersOpen}
                isDataLoading={isDataLoading}
                res={filteredList(res)}
                setDatesRange={setDatesRange}
                datesRange={datesRange}
              />
                :
                <DataGrid
                  setRes={setRes}
                  filters={filters}
                  setFilters={setFilters}
                  clearFilter={() => { setFilters({}); setDatesRange([{ startDate: null, endDate: null, key: 'selection' }]) }}
                  setFiltersOpen={setFiltersOpen}
                  isDataLoading={isDataLoading}
                  res={filteredList(res)}
                  setDatesRange={setDatesRange}
                  datesRange={datesRange} />)
            }
            {!showUserList && !showUserApprovalList && !showWhatsApp && !showWhatsAppChat && !showBlocked && !showAddImage && !showDataGrid && showSRGrid &&
              (process.env.REACT_APP_DEVICETYPE === "nativeApp" ? <DataGrid2
                setRes={setStockReportRes}
                filters={srFilters}
                setFilters={setSRFilters}
                clearFilter={() => { setSRFilters({}); setSRDatesRange([{ startDate: null, endDate: null, key: 'selection' }]) }}
                setFiltersOpen={setSRFiltersOpen}
                isDataLoading={isDataLoading}
                res={srFilteredList(stockReportRes)}
                setDatesRange={setSRDatesRange}
                datesRange={srDatesRange} />
                :
                <DataGrid2Web
                  setRes={setStockReportRes}
                  filters={srFilters}
                  setFilters={setSRFilters}
                  clearFilter={() => { setSRFilters({}); setSRDatesRange([{ startDate: null, endDate: null, key: 'selection' }]) }}
                  setFiltersOpen={setSRFiltersOpen}
                  isDataLoading={isDataLoading}
                  res={srFilteredList(stockReportRes)}
                  setDatesRange={setOffersDateRange}
                  datesRange={offersDatesRange} />)
            }
            {showUserList && <UserList />}
            {showUserApprovalList && <UserApprovalList />}
            {showWhatsApp && <WhatsAppPopup whatsAppMessageResults={whatsAppMessageResults} showDefault={showDefault} isSendInProgress={isSendInProgress} showInputField={true} handleCancel={() => {
              setShowWhatsApp(false);
              setShowDefault(false);
              dispatch(setShowWhatsAppChat(false))
            }} handleSend={handleSend} />}
            {showWhatsAppChat && <WhatsappChat />}
            {showSummary && <WhatsappChatSummary />}
            {showMessagesTable && <MessagesTable />}
            {showBlocked && <BlockNumbers handleCancel={() => { setShowBlocked(false) }} />}
            {showAddImage && <DefaultData handleCancel={() => { setShowAddImage(false) }} />}
            {
              res && <FiltersPopup filtersOpen={filtersOpen} filters={filters} setFilters={setFilters} setFiltersOpen={setFiltersOpen} apiData={res} />
            }
            {
              stockReportRes && <SRFiltersPopup filtersOpen={srFiltersOpen} filters={srFilters} setFilters={setSRFilters} setFiltersOpen={setSRFiltersOpen} apiData={stockReportRes} />
            }

            {/* {
              whatsAppMessageResults && <WhatsAppMessageResultsDialoge handleDone={() => {setWhatsAppMessageResults(null)}} whatsAppMessageResults={whatsAppMessageResults} />
            } */}
          </Box>
        </Container>
        <Dialog open={showLogout} onClose={e => setLogoutPopup(false)}>
          <DialogContent>
            <Typography variant={"body1"}>Are you sure?</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant={"outlined"} color={"error"} onClick={e => setLogoutPopup(false)}>Cancel</Button>
            <Button variant={"outlined"} color={"primary"} onClick={e => dispatch(logOut())}>Logout</Button>
          </DialogActions>
        </Dialog>
      </main>
    </div>
  );
};

export default Layout;
