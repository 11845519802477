import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { url } from '../../constant';
import { List, ListItem, ListItemText } from '@mui/material';
import { toastError, toastSuccess } from '../../utils';


function BlockNumbers(props) {
  const [numbers, setNumbers] = useState("")
  const [blockedNumbers, setBlockedNumbers] = useState([])
  const [isSendInProgress, setisSendInProgress] = useState("")

  useEffect(() => {
    getBlockList()
  }, [])

  const getBlockList = () => {
    axios.get(`${url}/simistocks/block_number_details`, 
    {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")).token}`}}).then(res => {
      setNumbers(res.data.blocked_numbers.join(","))
    })
    .catch(e => {

    })
  }

  const handleSend = (e) => {
    e.preventDefault(); 
    setisSendInProgress(true)
    axios.post(`${url}/simistocks/update_user`, {blocked_numbers: numbers.split(",")}, 
    {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")).token}`}}).then(res => {
      getBlockList()
      setisSendInProgress(false)
      toastSuccess(res.data)
    })
    .catch(e => {
      toastError(e.response.data)
    })
  }
  return (
    <div style={{marginTop: "0px", margin: "auto", maxWidth: "600px"}}>
        <DialogTitle sx={{textAlign: "center"}}>Block Numbers</DialogTitle>
        <DialogContent sx={{width: "100%", maxHeight: "300px"}} style={{overflowX: "hidden"}}>
            <>
              <br/>
              <label for="numbers"><b>Enter numbers sparated by coma:</b></label>
              <br/>
              <textarea
                name="numbers"
                inputMode={"decimal"}
                value={numbers}
                style={{width: "100%"}}
                onChange={(e) => setNumbers(e.target.value)}
                placeholder="Ex- 9876543210,8796543210"
                rows={5}
              />
            </>
        </DialogContent>
        <DialogActions style={{display: "flex", justifyContent: "center"}}>
          <Button variant="outlined" style={{border: "1px solid rgba(25, 118, 210, 0.5)", color: "#1976d2"}} onClick={props.handleCancel}>Cancel</Button>
          <Button variant='contained' style={{backgroundColor: "#1976d2"}} onClick={handleSend} >
            {!isSendInProgress ? "Save" :
              <CircularProgress style={{color: "#fff", opacity: "0.7", height: "2rem", width: "2rem"}} />}
            </Button>
        </DialogActions>
      </div>
  )
}

export default BlockNumbers