import React from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import WhatsAppList from "./WhatsAppList"
import ResultList from './ResultList';

function WhatsAppMessageResultsDialoge(props) {
  return (
    <Dialog open={true} style={{overflowX: "hidden"}}>
        <DialogTitle>Whatsapp results</DialogTitle>
        <DialogContent sx={{width: {xs: "300px", md: "400px"}, maxHeight: "500px"}}>
          <ResultList whatsAppMessageResults={props.whatsAppMessageResults} />
        </DialogContent>
        <DialogActions style={{display: "flex", justifyContent: "center"}}>
          <Button variant="contained" onClick={props.handleDone}>Done</Button>
        </DialogActions>
      </Dialog>
  )
}

export default WhatsAppMessageResultsDialoge