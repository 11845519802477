import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import wp from "./wp.png"

export default function ActionAreaCard(props) {
  return (
    <Card sx={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}>
      <CardActionArea style={{padding: "8px", boxShadow: "25px 34px 33px", display: "grid"}}>
        {props.isImage ? <CardMedia
          component="img"
          height="160"
          image={props.image || wp}
          sx={{width: {xs: "100%", md: "80%"}}}
          alt="template"
        /> :
        <object style={{width: "100%"}} data={props.image} ></object>}
        <CardContent style={{display: "grid", gap: "1rem", paddingBottom: 0}}>
          <Typography variant="body2" color="text.secondary">
            {props.texts}
          </Typography>
          <Typography variant="body2" style={{display: "flex", justifyContent: "center"}} color="text.secondary">
            {
              props.buttons && props.buttons.map(button => {
                return <Button variant='outlined' >{button.text}</Button>
              })
            }
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}