import { toast } from 'react-toastify';

const makeid = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const toastError = (mesg) => {
    if (mesg && mesg != '') {
        toast.error((mesg), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            toastId: makeid(4)
        });
    }
}

export const toastSuccess = (mesg) => {
    if (mesg && mesg != '') {
        toast.success((mesg), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            toastId: makeid(4)
        });
    }
}

export function formatIndianNumber(number) {
    // Check if the input is a valid number
    if (isNaN(number)) {
      return "Invalid number";
    }

    return Number(number).toLocaleString("en-IN")

    // Convert the number to a string
    const numberStr = number.toString();
  
    // Split the number into parts before and after the decimal point
    const parts = numberStr.split(".");
  
    // Format the integer part with commas
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
    // If there is a decimal part, add it back
    if (parts.length === 2) {
      return `${integerPart}.${parts[1]}`;
    } else {
      return integerPart;
    }
  }
  
  // Example usage:
  const formattedNumber = formatIndianNumber(123456789.12345);
// console.log(formattedNumber); // Output: "12,34,56,789.12345"
  