import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    showWhatsAppChat: false,
    showSummary: false,
    showMessagesTable: false,
    messages: [],
    selectedUser: null,
}


export const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setShowWhatsAppChat: (state, action) => {
            state.showWhatsAppChat = action.payload;
        },
        setMessages: (state, action) => {

            console.log(action);
            
            state.messages = action.payload;
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload;
        },
    },
    extraReducers: {
        
    },
});
export const { setShowWhatsAppChat, setMessages, setSelectedUser } = layoutSlice.actions;
export default layoutSlice.reducer;