import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { url } from '../../constant';
import { toastSuccess } from '../../utils';

let permissions = {
    admin: null,
    sales: null,
    stock: null,
    whatsAppBulk: null,
    whatsAppChat: null,
    whatsAppStatus: null,
    blockList: null,
    quickWA: null,
    attachment: null,
    device: null
}

export default function FormDialog(props) {

    const [approved, setapproved] = React.useState(props.selectedRow ? props.selectedRow.approved : "")
    const [email, setEmail] = React.useState(props.selectedRow ? props.selectedRow.email : "")
    const [deviceDetails, setDeviceDetails] = React.useState("")
    const [deviceName, setDeviceName] = React.useState(props.selectedRow ? props.selectedRow.deviceName : "")
    const [otp_receiver_number, setOtpReceiversNumber] = React.useState(props.selectedRow ? props.selectedRow.otp_receiver_number : "")
    const [isDataLoading, setIsDataLoading] = React.useState(false)
    const [accessAllowed, setAccessAllowed] = React.useState(props.selectedRow ? {...permissions, ...props.selectedRow.accessAllowed} : permissions)
    const [adminAccessAllowed, setAdminAccessAllowed] = React.useState({...permissions, ...JSON.parse(localStorage.getItem("login"))?.access_allowed} || permissions)
    const adminUser = JSON.parse(localStorage.getItem("login")) ? JSON.parse(localStorage.getItem("login")).admin : false

    console.log("adminAccessAllowed", adminAccessAllowed);

    const createUser = () => {
        setIsDataLoading(true)
        let payload = {
            ...props.data, "is_approved": approved, "email": email, "device_name": deviceName, "access_allowed": accessAllowed, otp_receiver_number
        }
        axios.post(`${url}/simistocks/update_app_user`, payload, { headers: { "Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}` } }).then((res) => {
            setIsDataLoading(false)
            props.handleCancel()
            toastSuccess("User Details Updated")
        }).catch(e => { 
          // console.log(e); setIsDataLoading(false) 
            toastSuccess("User Details update Failed. Please try again!!")
        })
    }
    
    const deleteUser = () => {
        setIsDataLoading(true)
        let payload = {"delete": true,"id": props.selectedRow.id}
        
        axios.post(`${url}/simistocks/update_app_user`, payload, { headers: { "Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}` } }).then((res) => {
            setIsDataLoading(false)
            props.handleCancel()
            toastSuccess("User Details deleted")
        }).catch(e => { 
          // console.log(e); setIsDataLoading(false) 
            toastSuccess("User Details delete Failed. Please try again!!")
        })
    }

    const handleFeatureAccessChange = e => {
        // if(e.target.name === "admin") setAccessAllowed({...accessAllowed, [e.target.name]: !accessAllowed[e.target.name], device: !accessAllowed[e.target.name]})
        // else 
        setAccessAllowed({...accessAllowed, [e.target.name]: !accessAllowed[e.target.name]})
    }

  // console.log("accessAllowed", accessAllowed);

    return (
        <div>
            <Dialog open={true} style={{ minWidth: "400px", minHeight: "200px" }}>
                <DialogTitle>{"Apporve Devices"}</DialogTitle>
                <DialogContent sx={{p: "12px !important", px: "15px !important"}}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
                        <TextField
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            label="Device Id"
                            type="text"
                            value={props.selectedRow.fcmId}
                            disabled
                        />
                        <TextField
                            label="Device Name"
                            type="text"
                            value={deviceName}
                            sx={{gridColumnStart: 1, gridColumnEnd: 3}}
                            onChange={(e) => setDeviceName(e.target.value)}
                        />
                        <TextField
                            label="OTP Receiver's Number"
                            type="text"
                            value={otp_receiver_number}
                            sx={{gridColumnStart: 1, gridColumnEnd: 3}}
                            onChange={(e) => setOtpReceiversNumber(e.target.value)}
                        />
                        <FormControlLabel sx={{m: 0}} control={<Checkbox onClick={() => setapproved(!approved)} checked={approved} />} label="Approve" />
                        {/* <TextField
                            label="File Name"
                            type="text"
                            value={fileName}
                            onChange={(e) => setFileName(e.target.value)}
                        /> */}
                        {
                            approved && (
                            adminUser ?
                                <>
                                    {(accessAllowed.admin !== undefined && accessAllowed.admin !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"admin"} checked={accessAllowed.admin} onClick={handleFeatureAccessChange} />} label="Admin Previlages" />}
                                    {(accessAllowed.sales !== undefined && accessAllowed.sales !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"sales"} checked={accessAllowed.sales} onClick={handleFeatureAccessChange} />} label="Sales" />}
                                    {(accessAllowed.stock !== undefined && accessAllowed.stock !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"stock"} checked={accessAllowed.stock} onClick={handleFeatureAccessChange} />} label="Stock" />}
                                    {(accessAllowed.whatsAppBulk !== undefined && accessAllowed.whatsAppBulk !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"whatsAppBulk"} checked={accessAllowed.whatsAppBulk} onClick={handleFeatureAccessChange} />} label="WhatsApp Bulk" />}
                                    {(accessAllowed.whatsAppChat !== undefined && accessAllowed.whatsAppChat !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"whatsAppChat"} checked={accessAllowed.whatsAppChat} onClick={handleFeatureAccessChange} />} label="WhatsApp Chat" />}
                                    {(accessAllowed.whatsAppStatus !== undefined && accessAllowed.whatsAppStatus !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"whatsAppStatus"} checked={accessAllowed.whatsAppStatus} onClick={handleFeatureAccessChange} />} label="Promotional Message Status" />}
                                    {(accessAllowed.blockList !== undefined && accessAllowed.blockList !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"blockList"} checked={accessAllowed.blockList} onClick={handleFeatureAccessChange} />} label="Block List" />}
                                    {(accessAllowed.quickWA !== undefined && accessAllowed.quickWA !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"quickWA"} checked={accessAllowed.quickWA} onClick={handleFeatureAccessChange} />} label="Quick WA" />}
                                    {(accessAllowed.attachment !== undefined && accessAllowed.attachment !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"attachment"} checked={accessAllowed.attachment} onClick={handleFeatureAccessChange} />} label="Attachment" />}
                                    {(accessAllowed.device !== undefined && accessAllowed.device !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"device"} checked={accessAllowed.device} onClick={handleFeatureAccessChange} />} label="Device" />}
                                </>
                            :
                                <>
                                    {(adminAccessAllowed.admin !== undefined && accessAllowed.admin !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"admin"} checked={accessAllowed.admin} onClick={handleFeatureAccessChange} />} label="Admin Previlages" />}
                                    {(adminAccessAllowed.sales !== undefined && accessAllowed.sales !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"sales"} checked={accessAllowed.sales} onClick={handleFeatureAccessChange} />} label="Sales" />}
                                    {(adminAccessAllowed.stock !== undefined && accessAllowed.stock !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"stock"} checked={accessAllowed.stock} onClick={handleFeatureAccessChange} />} label="Stock" />}
                                    {(adminAccessAllowed.whatsAppBulk !== undefined && accessAllowed.whatsAppBulk !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"whatsAppBulk"} checked={accessAllowed.whatsAppBulk} onClick={handleFeatureAccessChange} />} label="WhatsApp Bulk" />}
                                    {(adminAccessAllowed.whatsAppChat !== undefined && accessAllowed.whatsAppChat !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"whatsAppChat"} checked={accessAllowed.whatsAppChat} onClick={handleFeatureAccessChange} />} label="WhatsApp Chat" />}
                                    {(adminAccessAllowed.whatsAppStatus !== undefined && accessAllowed.whatsAppStatus !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"whatsAppStatus"} checked={accessAllowed.whatsAppStatus} onClick={handleFeatureAccessChange} />} label="Promotional Message Status" />}
                                    {(adminAccessAllowed.blockList !== undefined && accessAllowed.blockList !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"blockList"} checked={accessAllowed.blockList} onClick={handleFeatureAccessChange} />} label="Block List" />}
                                    {(adminAccessAllowed.quickWA !== undefined && accessAllowed.quickWA !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"quickWA"} checked={accessAllowed.quickWA} onClick={handleFeatureAccessChange} />} label="Quick WA" />}
                                    {(adminAccessAllowed.attachment !== undefined && accessAllowed.attachment !== null) && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"attachment"} checked={accessAllowed.attachment} onClick={handleFeatureAccessChange} />} label="Attachment" />}
                                    {(adminAccessAllowed.device) !== undefined && accessAllowed.device !== null && <FormControlLabel sx={{m: 0}} control={<Checkbox name={"device"} checked={accessAllowed.device} onClick={handleFeatureAccessChange} />} label="Device" />}
                                </>
                            )
                        }
                    </div>
                </DialogContent>
                <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                    <Button variant="outlined" style={{ border: "1px solid rgba(25, 118, 210, 0.5)", color: "#1976d2", padding: "5px 10px" }} onClick={props.handleCancel}>Cancel</Button>
                    <Button variant='contained' style={{ backgroundColor: "#1976d2", color: "#fff", padding: "5px 10px" }} onClick={createUser} >{"Update"}</Button>
                    <Button variant='contained' style={{ backgroundColor: "#d32f2f", color: "#fff", padding: "5px 10px" }} onClick={deleteUser} >{"Delete"}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
