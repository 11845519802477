import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

function ResultList(props) {
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <ListItem
          disableGutters
        //   secondaryAction={
        //     <IconButton>
        //       Status
        //     </IconButton>
        //   }
        >
          <ListItemText primary={"Phone Number"} />
          <ListItemText primary={"Status"} />
        </ListItem>
      {Object.keys(props.whatsAppMessageResults).map((value) => (
        <ListItem
          key={value}
          disableGutters
        //   secondaryAction={
        //     <IconButton>
              
        //     </IconButton>
        //   }
        >{console.log("value", value, props.whatsAppMessageResults)}
          <ListItemText primary={value} />
          <ListItemText primary={ props.whatsAppMessageResults[value] === "success" ? <CheckCircleOutlineIcon style={{color: "#4bae4f"}} /> : <CancelOutlinedIcon style={{color: "#f34235"}} />} />
        </ListItem>
      ))}
    </List>
  )
}

export default ResultList