import { Box, Button, Dialog, DialogContent, Link, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import forbidden from "./forbidden.png"
import LogoutIcon from '@mui/icons-material/Logout';
import { logOut } from '../../slices/auth';
import { useDispatch } from 'react-redux';
import simiInfoIcon from "./simi-info-icon.png"
import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import { url } from '../../constant';
import { toastError, toastSuccess } from '../../utils';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        ...theme.mixins.toolbar,
        position: "absolute",
        top: 20,
        width: "90%",
        backgroundColor: "#8fe9ff36"
    },
  }));

function Forbidden() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [deviceName, setDeviceName] = useState("")
    const [open, setOpen] = useState("")
    let access = JSON.parse(localStorage.getItem("login"))

    const sendDeviceDetails = () => {
        // console.log("access", access);
        let payload =  {"device_name": deviceName,"id": access?.user_app_id}

        axios.post(`${url}/simistocks/update_app_user`, payload, {headers: {Authorization: "Token "+access?.token}})
        .then(res => {
            setOpen(false)
            toastSuccess("Device details sent!")
        })
        .catch(error => {
            toastError(error.response.message || "Error!")
          // console.log(error.response);
        })

    }

    return (
        <Box
            display={"grid"}
            alignContent={"center"}
            width={"100%"}
            height={"100vh"}
            bgcolor={"#f3f3f3"}
            textAlign={"center"}
            fontWeight={"600"}
        >
            <div className={classes.toolbar} style={{minHeight: "max-content", display: "flex", justifyContent: "space-between", marginTop: "-20px", padding: "10px 25px", alignItems: "center"}}>
                <img src={simiInfoIcon} style={{height: "52px", margin: "auto"}} alt="simi infotech" />  
                <LogoutIcon onClick={() => dispatch(logOut())} />
            </div>
            <Box width={"70%"} maxWidth={"300px"} component={"img"} src={forbidden} margin={"auto"}/>
            <Typography variant='h3' fontWeight={"bold"}>403</Typography>
            <Typography variant='h5'>Device is not authorised!</Typography>
            <Typography variant='h6'>Please register your device name and contact admin to get the device authorized</Typography>
            <Box sx={{display: "flex", justifyContent: "center", mt: "20px"}}>
                <TextField label={"Device Name"} type='text' onChange={e => setDeviceName(e.target.value)}/>
                <Button variant={"contained"} color={"primary"} onClick={sendDeviceDetails}>Register</Button>
            </Box>

            {/* <Link onClick={() => setOpen(true)} variant={"subtitle2"} sx={{textDecoration: "underline"}} >click here to register device name</Link> */}
            
            {/* <Dialog open={open} onClose={() => setOpen(false)} disableScrollLock>
                <DialogContent sx={{display: "flex", justifyContent: "center", }}>
                    <TextField label={"Device Name"} type='text' onChange={e => setDeviceName(e.target.value)}/>
                    <Button variant={"contained"} color={"primary"} onClick={sendDeviceDetails}>Submit</Button>
                </DialogContent>
            </Dialog> */}
        </Box>
    )
}

export default Forbidden