import React from 'react';
import { Button } from '@mui/material';

const DownloadButton = ({ fileUrl }) => {

    console.log(fileUrl);
    
  
  // Function to download file
  const handleDownload = () => {
    const url = `${fileUrl}`;

    // Check if the InAppBrowser plugin is available
    if (window.cordova && window.cordova.InAppBrowser) {
      // Use InAppBrowser to download the file
      window.cordova.InAppBrowser.open(url, '_system', 'location=yes');
    } else {
      // If plugin is not available, fall back to default browser
      window.open(url, '_blank');
    }
  };

  return (
    <div>
        Document <br/>
        <span className={"link"} onClick={handleDownload}>
            {fileUrl.split("/")[fileUrl.split("/").length - 1]}
        </span>
    </div>
  );
};

export default DownloadButton;