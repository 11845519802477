import axios from "axios";
import { store } from "./app/store";
import { forbidden, logOut } from "./slices/auth";
import Cookies from 'js-cookie'

const getToken = () => {
    // console.log("_token--",Cookies.get('_se_manager_token'))
    return JSON.parse(localStorage.getItem('login'))?.token;
};

const axiosInstance = axios.create({
    baseURL: "https://admin.simiinfotech.com",
    headers: {
        Authorization: getToken() ? `Token ${getToken()}` : "",
    },
});

axiosInstance.interceptors.request.use((req) => {
    const { auth } = store.getState();
    if (auth._token) {
        req.headers.Authorization = `Token ${auth._token}`;
    }
    return req;
});

axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        const { status } = err.response || {status: undefined};
      // console.log("status", status);
        if (!status || status === 401) {
            store.dispatch(logOut());
        } else if(status === 403) {
            localStorage.setItem("login", JSON.stringify(err.response.data))    
            store.dispatch(forbidden());
            return;
        }
        return Promise.reject(err.response);
    }
);

export default axiosInstance;
