import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ApproveUserPopup from "./ApproveUserPopup.js"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DeleteWarningPopup from './DeleteWarningPopup';
import { url } from '../../constant';

const columns = [
  // { id: 'id', label: 'Id', maxWidth: 50 },
  { id: 'email', label: 'Email', minWidth: 100, maxWidth: 130},
  { id: 'fcmId', label: 'FCM Id', minWidth: 100, maxWidth: 130, format: (token) => token ? `${token.slice(0, 10)}...` : ""},
  { id: 'approved', label: 'Approved', align: "center", minWidth: 100, maxWidth: 130},
  { id: 'deviceName', label: 'Device Name', minWidth: 100, maxWidth: 130},
  { id: 'otp_receiver_number', label: 'OTP Receiver Number', minWidth: 100, maxWidth: 130},
  { id: 'deviceDetails', label: 'Device Details', minWidth: 100, maxWidth: 150, format: o => {
    let d = ""
    for(let key in o){
        d+=key + " - " + o[key] + ", "
    }
    return d || "N/A"
  } },
//   { id: 'accessAllowed', label: 'Access Allowed', minWidth: 100, maxWidth: 150 }
];

function createData(id, fcmId, email, approved, deviceName, deviceDetails, accessAllowed, otp_receiver_number) {
  return {id, fcmId, email, approved, deviceName, deviceDetails, accessAllowed, otp_receiver_number};
}

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [res, setRes] = React.useState(null);
  const [isDataLoading, setIsDataLoading] = React.useState(true)
  const [rows, setRows] = React.useState([])
  const [userPopup, setUserPopup] = React.useState(false)
  const [selectedRow, setSelectedRow] = React.useState(null)
  const [userId, setUserId] = React.useState(null)
  const [showWarning, setShowWarning] = React.useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const userDeleteHandler = (id) => {

    setShowWarning(true)
    setUserId(id)
  }

  const getListUsers = () => {
    if(localStorage.getItem("login"))
    setIsDataLoading(true)
    axios.get(`${url}/simistocks/list_app_users`, {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")).token}`}}).then((res) => {
        setRes(res.data)
        setIsDataLoading(false)
        setRows(res.data.map(user => createData(user.id, user.fcm_id, user.user__user__email, user.is_approved, user.device_name, user.device_details, user.access_allowed, user.otp_receiver_number)))
    }).catch(e=>{console.log(e);setIsDataLoading(false)})
  }

  React.useEffect(() => {
    getListUsers()
  }, [])
  
  React.useEffect(() => {
    getListUsers()
  }, [userPopup, showWarning])

  return (
    <div style={{display: "grid", gap: "1rem"}}>
        <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
            <Typography variant="h6" fontWeight={"bold"} gutterBottom>
                Device Approval
            </Typography>
            {/* <Button variant="contained" onClick={() => setUserPopup(true)}>Apporve Devices</Button> */}
        </div>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: "75vh", whiteSpace: "nowrap" }}>
            <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                {columns.map((column) => (
                    <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, width: column.width }}
                    >
                    {column.label}
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows && rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                    return (
                    <TableRow hover style={{cursor: "pointer"}} role="checkbox" tabIndex={-1} key={row.code} onClick={() => {setUserPopup(true);setSelectedRow(row)}}>
                        {columns.map((column) => {
                        return (
                            <TableCell key={column.id} align={column.align}>
                                { column.id === "actions" ? 
                                    <>
                                        <EditIcon onClick={() => {setUserPopup(true);setSelectedRow(row)}} />
                                        &nbsp;&nbsp;&nbsp;
                                        <DeleteIcon onClick={() => userDeleteHandler(row.id)} />
                                    </> 
                                    :  column.id === "approved"  ? row[column.id] ? <CheckCircleIcon style={{color: "#4bae4f"}}/> : <CancelIcon  style={{color: "#f34235"}} /> 
                                    : column['format'] ? `${column.format(row[column.id] || "N/A")}` : `${row[column.id] || "N/A"}`}
                            </TableCell>
                        );
                        })}
                    </TableRow>
                    );
                })}
            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Paper>
        {userPopup && <ApproveUserPopup data={res.find(r => r.id === selectedRow.id)} selectedRow={selectedRow} handleCancel={() => {setUserPopup(false);setSelectedRow(null)}}/>}
        {
          showWarning && <DeleteWarningPopup delete={() => {
            let payload = {"delete":true,"id":userId}
            axios.post(`${url}/simistocks/update_user`, payload, { headers: { "Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}` } }).then((res) => {
                setShowWarning(false)
              }).catch(e => {console.log(e)})
          }} handleCancel={() => {setShowWarning(false)}} />
        }
    </div>
  );
}
