
// exchane_wp_msg
import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
// import columns from "../columnFormat"
// import { downloadCSV } from 'react-admin';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import jsonExport from 'jsonexport/dist';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import noRecordsFound from "./no_records_found.png"
import { Bars } from 'react-loader-spinner';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WhatsAppPopup from '../User/WhatsApp';
import SROffersGrid from '../User/SROffersGrid';
import axios from 'axios';
import WhatsAppMessageResultsDialoge from '../User/WhatsAppMessageResultsDialoge';
import FilterListIcon from '@mui/icons-material/FilterList';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FilledInput from '@mui/material/FilledInput';
import AlertDialog from './ConfirmationDialog';
import { useState } from 'react';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import { url } from '../../constant';
import { List, ListItem } from '@mui/material';
import SelectAutoComplete from "./SelectAutoComplete"

const columns = [
  { id: 'a1', label: 'File name ', minWidth: 170, align: 'left', format: (value) => <p style={{width: "12rem",fontWeight: 500, color: typeof value === "string" && typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
  { id: 'b1', label: 'Stock item', minWidth: 200, align: 'left', format: (value) => <p style={{width: "12rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
  { id: 'c1', label: 'Stock group', minWidth: 200, align: 'left', format: (value) => <p style={{width: "9rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#0b88fe"}}>{value}</p>, },
  { id: 'd1', label: 'Shop', minWidth: 130, align: 'left', format: (value) => <p style={{width: "10rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
  { id: 'e1', label: 'Qty', minWidth: 170, align: 'center', format: (value) => <p style={{width: "2rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
  { id: 'f1', label: 'Price', minWidth: 170, align: 'left', format: (value) => <p style={{width: "5rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
  { id: 'schemes', label: 'Offers', minWidth: 170, align: 'left', format: (value) => <p style={{width: "5rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
];

const useStyles = makeStyles({
})

function createData(a1, b1, c1, d1, e1, f1, schemes) {
    return { a1, b1, c1, d1, e1, f1, schemes};
  }

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const exporter = (selectedStocks, stocks) => {
  // let head = columns.map(headCell => {return { : }})
  // console.log("selectedStocks", selectedStocks, stocks);
  let stockForExport = []
  const stocksForExport = selectedStocks.map(selectedStock => {
      for(let i in stocks){
        if(`${stocks[i].K4}_${stocks[i].K8}` === selectedStock){
          // console.log("stocks[i]", stocks[i]);
          let {K1, K2, K3, K4, K5, K6, K7, K8, K9, K10, K11, K12, K13, K14, K15, K16, K17, K18, K19} = stocks[i]
          stockForExport.push({"Date": K1, "VCH Type": K2, "Invoice Number": K3, "Customer Name": K4, 
          "Contact": K5, "Stock Item": K6, "Stock Group": K7, "IMEI Number": K8, "Godown": K9, "Quantity": K10, "Total": K11, 
          "Finance Name": K12, "Brand": K13, "Salesman": K14, "Category": K15, "Stock Alias": K16, "DOB": K18, "PDF File Name": K19})
        }
      }
      return stockForExport
  });

  // jsonExport(stockForExport, {}, (err, csv) => {
  //     downloadCSV(csv, 'stocks');
  // });
};

const filterNumbers = (resData, setNumberList, setShowWhatsAppPopup) => {
  // let numberList = []

  // console.log("resData", resData)

  // setNumberList(numberList)
  setShowWhatsAppPopup(true)
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            onClick={(event) => {
              if(event.target.checked){
                props.setSelected([])
                numberList = []
                // console.log("new sesl");
              } 
              props.rows.map((row) => {if(row.K8 !== "No"){props.handleClick(event, `${row.row}`, row);props.handleNumberList(row.id, row.K5, row)}})}}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {columns.map((headCell) => (
          headCell.id === "Exchange" ? numSelected > 0 ? 
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell> : <></>
          :
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const [datePopupOpen, setDatePopupOpen] = React.useState(false)
  const [startDate, setStartDate] = React.useState(props.datesRange[0].startDate)
  const [endDate, setEndDate] = React.useState(props.datesRange[0].endDate)
  const [searchQuery, setSearchQuery] = React.useState("")
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [showSearchByGroup, setShowSearchByGroup] = React.useState(false)
  const [showSearchByItems, setShowSearchByItems] = React.useState(false)
  const [stockItemList, setStockItemList] = React.useState([])
  const [stockGroupList, setStockGroupList] = React.useState([])
  const [filters, setFilters] = React.useState(props.filters)
  const [datesRange, setDatesRange] = React.useState(
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  );

  const deleteHandler = () => {
    axios.post(`${url}/simistocks/delete_data`,
      {},
      // {"phone_numbers":numberLs}, 
      {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}`}})
      .then(res => {
       setAlertOpen(false)
       props.setRes([])
      })
      .catch(error => console.log(error.message))
  }

  React.useEffect(() => {
    if(props.datesRange[0].startDate){
      setStartDate(moment(props.datesRange[0].startDate))
    }
    if(props.datesRange[0].endDate){
      setEndDate(moment(props.datesRange[0].endDate))
    }
  }, [props.datesRange])

  React.useEffect(() => {
  // console.log("props.apiData", props.apiData);
    let allStockItem = props.apiData?.map((stock) => stock.b1)
    let set = new Set(allStockItem)
    let distinctStockItems = [...set].filter(ob => ob != null)
    setStockItemList(distinctStockItems)
  },[props.apiData])

  React.useEffect(() => {
  // console.log("props.apiData", props.apiData);
      let allStockGroup = props.apiData?.map((stock) => stock.c1)
      let set = new Set(allStockGroup)
      let distinctStockGroup = [...set].filter(ob => ob != null)
      setStockGroupList(distinctStockGroup)
  },[props.apiData])

  const handleFilterSelect = (e) => {
    if(e.target.value === null){
      let filter = filters
      delete filter[e.target.name]
      setFilters(filter)
    }
    else setFilters({...filters, [e.target.name]: e.target.value})

    props.setFilters({...props.filters, ...{...filters, [e.target.name]: e.target.value}, searchQuery: searchQuery})
  }

  let direction = window.screen.width < 480 ? "column" : ""
  let align = window.screen.width < 480 ? "start" : ""
  return (
    <>
      <Toolbar
        sx={{
          flexDirection: "row",
          alignItems: align,
          flexWrap: "wrap",
          justifyContent: "space-between",
          p: 0,
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected === 0 && <form style={{display: "flex", width: "100%", justifyContent: "center"}} onSubmit={(e) => {e.preventDefault();props.setFilters({...props.filters, ...filters, searchQuery: searchQuery})}}>
          <List style={{width: "100%"}}>
            <ListItem sx={{display: showSearchByGroup || showSearchByItems ? "none" : ""}}>
              <FormControl sx={{ m: 0, width: '100%' }} variant="filled">
                <InputLabel htmlFor="filled-adornment-password">Search</InputLabel>
                <FilledInput
                style={{border: "1px solid rgb(18 26 78 / 31%)", borderRadius: "5px", cursor: "pointer", background: "#fff", color: "rgb(18, 26, 78)"}}
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton type='submit' style={{background: "#000 !important"}}><SearchIcon /></IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </ListItem>
            <ListItem sx={{display: !showSearchByItems ? "none" : ""}}>
              <FormControl fullWidth>
                <SelectAutoComplete data={stockItemList} value={filters.stockItem} label="Search by Stock Items" handleFilterSelect={handleFilterSelect} name="stockItem" />
              </FormControl>
            </ListItem>
            <ListItem sx={{display: !showSearchByGroup ? "none" : ""}}>
              <FormControl fullWidth>
                <SelectAutoComplete data={stockGroupList} value={filters.stockGroup} label="Search by Stock Groups" handleFilterSelect={handleFilterSelect} name="stockGroup" />
              </FormControl>
            </ListItem>
          </List>
          <Tooltip title="Filters">
            <IconButton onClick={() => props.setFiltersOpen(true)}>
              <FilterListIcon style={{color: "#121a4e"}} />
            </IconButton>
          </Tooltip>
        </form>}
        {numSelected > 0 && (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        )}

        {numSelected === 0 && <div style={{display: "flex", flexWrap: "nowrap", gap: "1rem", paddingLeft: "10px", justifyContent: "space-between", width: "100%"}}>
          <FormControlLabel sx={{marginRight: 0}} control={<Checkbox 
            onClick={(event) => props.setFilters({...props.filters, showOffersItems: !props.filters.showOffersItems})}
            color="primary"
            checked={props.filters.showOffersItems}/>} label="Only offers" />
            {props.filters.showOffersItems && <form style={{marginLeft: "10px", width: "max-content"}} onSubmit={e => {
              e.preventDefault()
              props.setDatesRange([{startDate: datesRange[0].startDate, endDate: datesRange[0].endDate}])
            }}>
                <input style={{border: "1px solid #121a4e", borderRadius: "5px 0px 0px 5px", cursor: "pointer", padding: "0.4rem 1rem", background: "#fff", color: "rgb(18, 26, 78)", fontWeight: "bold"}} onChange={(e => setDatesRange([{...datesRange, startDate: e.target.value}]))} value={datesRange.startDate} type={"date"} placeholder="dd/mm/yyyy" />
                <input style={{border: "1px solid #121a4e", cursor: "pointer", padding: "0.4rem 1rem", background: "#fff", color: "rgb(18, 26, 78)", fontWeight: "bold"}} onChange={(e => setDatesRange([{...datesRange, endDate: e.target.value}]))} value={datesRange.endDate} type={"date"} />
                <input style={{border: "1px solid #121a4e", borderRadius: "0px 5px 5px 0px", cursor: "pointer", padding: "0.456rem 1rem", background: "#fff", color: "rgb(18, 26, 78)", fontWeight: "bold"}} type={"submit"} />
            </form>}
          
          <FormControlLabel control={<Checkbox 
            onClick={(event) => {setShowSearchByGroup(event.target.checked);setShowSearchByItems(false)}}
            color="primary"
            checked={showSearchByGroup}/>} label="Groups" />
          
          <FormControlLabel control={<Checkbox 
            onClick={(event) => {setShowSearchByItems(event.target.checked);setShowSearchByGroup(false)}}
            color="primary"
            checked={showSearchByItems}/>} label="Items" />
          {(props.showFilters || datesRange.startDate || datesRange.endDate ) && <Button onClick={() => {props.clearFilter({});setSearchQuery("");setDatesRange({startDate: "", endDate: ""});}} style={{border: "1px solid #121a4e", borderRadius: "5px", cursor: "pointer", padding: "0.4rem 1rem", background: "#fff", color: "rgb(18, 26, 78)", fontWeight: "bold", textDecoration: "line-through"}}>
            Filters
          </Button>}
          </div>
        }
        {props.selected.length > 0 && <Tooltip title="Export">
          <IconButton onClick={() => exporter(props.selected, props.res)}>
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>}
      </Toolbar>
      <AlertDialog deleteHandler={deleteHandler} handleClose={() => setAlertOpen(false)} open={alertOpen} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


let numberList = []

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showWhatsAppPopup, setShowWhatsAppPopup] = React.useState(false)
  const [hideDropdown, setHideDropdown] = React.useState(false)
  // const [numberList, setNumberList] = React.useState([])
  const [whatsAppMessageResults, setWhatsAppMessageResults] = React.useState(null)
  const [total, setTotal] = React.useState(0)
  const [quantity, setQuantity] = React.useState(0)
  const [exchangeList, setExchangeList] = useState([])
  const [openGridPopup, setOpenGridPopup] = useState(false)
  const [popupData, setPopupData] = useState([])
  const [showItemsWithScheme, setShowItemsWithScheme] = React.useState(false)

  const [isSendInProgress, setSendInProgress] = React.useState(false)

  const classes = useStyles()

  React.useEffect(() => {
    setRowsPerPage(10)
    setPage(0)
  }, [props.filters])

  const setExchangeValue = (row, exchageValue) => {


    let exchanges = [...exchangeList]

    let exist = exchanges.filter(row1 => `${row1.K4}_${row1.K8}` === `${row.row}`)
    if(exist.length > 0){

      if(exchageValue === ""){
        let index;
        exchanges.some(function(row1, i) { if (`${row1.K4}_${row1.K8}` === `${row.row}`) return (index = i); })
        delete exchanges[index]

        return
      }

      let index;
      exchanges.some(function(row1, i) { if (`${row1.K4}_${row1.K8}` === `${row.row}`) return (index = i); })
      exchanges[index]["exchage_value"] = exchageValue
    } else {
      let tempRow = {...row}
      tempRow['exchage_value'] = exchageValue
      exchanges.push(tempRow)
    }
    
    setExchangeList(exchanges)
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openGrid = (schemes) => {
    setOpenGridPopup(true)
    setPopupData(schemes)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => `${n.K4}_${n.K8}`);
      setSelected(newSelecteds);
      setExchangeList(rows);
      return;
    }
    setSelected([]);
  };

  const rows = props.res ? props.res.map(stock => createData(
    stock.a1,
    stock.b1,
    stock.c1,
    stock.d1,
    stock.e1,
    stock.f1,
    stock.schemes,
    )) : []

  const handleClick = (event, name, row) => {


    let exchanges = [...exchangeList]

    let exist = exchanges.filter(row1 => `${row1.K4}_${row1.K8}` === `${row.row}`)
    if(exist.length > 0){

      // if(exchageValue === ""){
      //   let index;
      //   exchanges.some(function(row1, i) { if (`${row1.K4}_${row1.K8}` === `${row.row}`) return (index = i); })
      //   delete exchanges[index]

      //   return
      // }

      let index;
      exchanges.some(function(row1, i) { if (`${row1.K4}_${row1.K8}` === `${row.row}`) return (index = i); })
      exchanges.splice(index, 1)
      // exchanges[index]["exchage_value"] = exchageValue
    } else {
      let tempRow = {...row}
      // tempRow['exchage_value'] = exchageValue
      exchanges.push(tempRow)
    }
    
    setExchangeList(exchanges)



    // const selectedIndex = selected.indexOf(name);
    // let newSelected = [];
    
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1),
    //   );
    // }


    // setSelected(newSelected);
  };

  const handleNumberList = (id, number, header) => {
    if(number.toLowerCase() === "no") return
    
    let newSelectedNumbers = [];

    // if(header) numberList = [];

    const selectedIndex = numberList.indexOf(`${id}_${number}`);
    
    if (selectedIndex === -1) {
      newSelectedNumbers = newSelectedNumbers.concat(numberList, `${id}_${number}`);
    } else if (selectedIndex === 0) {
      newSelectedNumbers = newSelectedNumbers.concat(numberList.slice(1));
    } else if (selectedIndex === numberList.length - 1) {
      newSelectedNumbers = newSelectedNumbers.concat(numberList.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedNumbers = newSelectedNumbers.concat(
        numberList.slice(0, selectedIndex),
        numberList.slice(selectedIndex + 1),
      );
    }

    numberList = newSelectedNumbers
  }

  const handleSend = (numberLs, data, imageFile, additionalTexts, exchangeTexts) => {

    if(exchangeList.length > 0){
      handleExchange(exchangeTexts, data, imageFile, additionalTexts)
      return
    }

    let formData = new FormData()

    // var blob = new Blob([new Uint8Array(file)], { type: "jpeg" });
    imageFile && formData.append("image", imageFile, imageFile.name);
    formData.append("data", JSON.stringify(data))
    additionalTexts && formData.append("free_field_msg", additionalTexts.replace(/\n/g, '\\n'))

    var longArray = numberLs;
    var size = 100;
    var count = 0;
    var result = []

    var newArray = new Array(Math.ceil(longArray.length / size)).fill("")
        .map(function() { return this.splice(0, size) }, longArray.slice());

        newArray?.forEach((arr, i) => {
          formData.delete("phone_numbers")
          formData.append("phone_numbers", JSON.stringify(arr))


          setSendInProgress(true)
          axios.post(`${url}/simistocks/simi_whatsapp`,
          formData,
          // {"phone_numbers":numberLs}, 
          {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}`}})
          .then(res => {
            count++
            result = {...result, ...res.data}
            if(count === newArray.length) {
              setSendInProgress(false)
              setHideDropdown(false)
              setWhatsAppMessageResults(result)
            }
          })
          .catch(error => {console.log(error.message); setSendInProgress(false); setHideDropdown(false)})
        })

    }

  const handleExchange = (numberLs, data, imageFile, additionalTexts, exchangeTexts) => {

    // var longArray = [{ "K1": "1-4-2021", "K2": "Pos Sales - N1", "K3": "N1/21-22/1", "K4": "Rajesh Singh", "K5": "7440855210", "K6": "VIVO Y20A 2021 3/64GB Dawn White", "K7": "HS - VIVO Y20A 2021 3+64GB", "K8": "864419055352253", "K9": "Shop - N1", "K10": 1, "K11": 11100, "K12": "No", "K13": "No", "K14": "Sm Amit Das", "K15": "HS - Smart Phones", "K16": "6935117831732", "K17": "111KAPIL", "id": "1", "row": "row_1" }]// [...exchangeList];
    var longArray = [...exchangeList];
  
    var size = 1;
    var count = 0;
    var result = []

    let formData = new FormData()
    imageFile && formData.append("image", imageFile, imageFile.name);
    formData.append("free_field_msg", exchangeTexts.replace(/\n/g, '\\n'));
    formData.append("template_data", JSON.stringify(data));

    // var newArray = [[{ "K1": "1-1-2022", "K2": "Pos Sales - N1", "K3": "N1/21-22/5004", "K4": "Jamil Ahmad", "K5": "7440855210", "K6": "XIAOMI REDMI 9i 4/64GB Green", "K7": "HS - XIAOMI REDMI 9i 4+64GB", "K8": "869805051979609", "K9": "Shop - N1", "K10": 1, "K11": 8999, "K12": "No", "K13": "No", "K14": "Sm Rahul", "K15": "HS - Smart Phones", "K16": "8908012587668", "K17": "4488BABLOOSALES", "id": "103", "row": "row_103", "exchange_value": "8000" }]]
    var newArray = new Array(Math.ceil(longArray.length / size)).fill("")
        .map(function() { return this.splice(0, size) }, longArray.slice());

        newArray?.forEach((arr, i) => {

    formData.append("data", JSON.stringify(arr))
    axios.post(`${url}/simistocks/exchange_wp_msg`,
      formData,
        // {"phone_numbers":numberLs}, 
        {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}`}})
        .then(res => {
          count++
          if(count === newArray.length) result.push(res.data)
          setWhatsAppMessageResults(result)
          setSendInProgress(false)
        })
        .catch(error => {console.log(error.message); setSendInProgress(false);});

        formData.delete("data")
      })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    let total = 0;
    let quantity = 0;
    
    if(props.res){
      for(let i=0; i<props.res.length; i++){
        if(!isNaN(props.res[i].K11)) total = total + props.res[i].K11
        if(!isNaN(props.res[i].K10)) quantity = quantity + props.res[i].K10
      }

      setTotal(total)
      setQuantity(quantity)
    }
  },[props.res])

  React.useEffect(() => {
    if(selected.length === 0){
      setHideDropdown(false)
      setExchangeList([])
    }
  },[selected])
  

  // const isSelected = (name) => selected.indexOf(name) !== -1;
  const isSelected = (name) => exchangeList.some(row => name === `${row.row}`);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box >
          {/* <div>
            <Card sx={{ minWidth: 275}}>
              <CardContent style={{display: "flex", gap: "1rem", justifyContent: "center"}}>
                <h3>Quantity: {quantity}</h3>
                <h3>Total: {parseFloat(total).toFixed(2)}</h3>
              </CardContent>
            </Card>
          </div> */}
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar apiData={props.res} setRes={props.setRes} filters={props.filters} setFilters={props.setFilters} showFilters={Object.keys(props.filters).length > 0} clearFilter={props.clearFilter} setFiltersOpen={props.setFiltersOpen} setShowWhatsAppPopup={setShowWhatsAppPopup} setHideDropdown={setHideDropdown} rows={rows} selected={selected} res={props.res} numSelected={exchangeList.length} setDatesRange={props.setDatesRange} exchangeList={exchangeList} datesRange={props.datesRange}/>
        <TableContainer sx={{maxHeight: "66vh", height: "70vh",}}>
          <Table
            aria-labelledby="tableTitle"
            size={'medium'}
            stickyHeader
            className='sticky-first-column'
            style={{maxHeight: "70vh", height: "max-content"}}
          >
           {rows.length > 0 ? <><EnhancedTableHead
              setSelected={setSelected}
              rows={rows}
              handleClick={handleClick}
              handleNumberList={handleExchange}
              numSelected={exchangeList.length}
              selected={selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(`${row.row}`);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.row}`}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => {handleClick(event, `${row.row}`, row);handleNumberList(row.id, row.K5, row)}}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                    {columns.map((column) => {
                      const value = row[column.id];
                    // console.log("value", value, row)
                      return (
                        column.id === "schemes" ? value?.length? 
                        <TableCell key={`${column.id}_${row.row}`} align={column.align}>
                          <Button onClick={() => openGrid(row.schemes)} variant='outlined' color='primary'>Check Offers</Button>
                        </TableCell> : <TableCell key={`${column.id}_${row.row}`} align={column.align}>
                            N/A
                        </TableCell>
                        :
                        <TableCell key={`${column.id}_${row.row}`} align={column.align}>
                            {column.format(value)}
                        </TableCell>
                      );
                    })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody></> : 
            props.isDataLoading ? 
            <TableBody>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Bars wrapperStyle={{justifyContent: "center", height: 400, alignItems: 'center'}} color="rgb(18, 26, 78)" height={80} width={80} /> 
                    </TableCell>
                </TableRow>
              </TableBody>
            :
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9}>
                    <div style={{display: "grid", justifyItems: "center"}}>
                      <img style={{width: "100%", maxWidth: "700px"}} src={noRecordsFound} alt='img' />
                      {false ? <p>No record found with selected date range, please try changing range</p>
                      : <p>No record found for this user</p>}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            }
          </Table>
        </TableContainer>
        {rows.length > 0 && <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />}
      </Paper>
      {
        showWhatsAppPopup && !whatsAppMessageResults && <WhatsAppPopup hideDropdown={hideDropdown} isSendInProgress={isSendInProgress} numberList={[...new Set(numberList.map(number => number.split("_")[1]))]} handleCancel={() => {setShowWhatsAppPopup(false);setHideDropdown(false)}} handleSend={handleExchange}  />
      }
      {
        openGridPopup && <SROffersGrid data={popupData} close={() => setOpenGridPopup(false)} />
      }
      {
        whatsAppMessageResults && <WhatsAppMessageResultsDialoge handleDone={() => {setWhatsAppMessageResults(null); setShowWhatsAppPopup(false)}} whatsAppMessageResults={whatsAppMessageResults} />
      }
    </Box>
  );
}


// 
// {datePopupOpen ?
//   <div className={classes.datePicker}>
//     <DateRange
//       editableDateInputs={true}
//       onChange={item => setDatesRange([item.selection])}
//       moveRangeOnFirstSelection={false}
//       ranges={datesRange}
//     />
//     <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
//       <button className={classes.applyButton} style={{cursor: "pointer"}} onClick={() => {props.setDatesRange(datesRange);toggleDatePicker()}}>Apply</button>
//       <button className={classes.applyButton} style={{background: "#ff003e", cursor: "pointer"}} onClick={toggleDatePicker}>Cancel</button>
//     </div>
//   </div>
//  : <>Date: <Button type="text" className={classes.applyButton} onClick={toggleDatePicker}>{`From: ${startDate ? startDate.format("DD-MM-YYYY") : "Select"} - To: ${endDate ? endDate.format("DD-MM-YYYY") : "Select"}`}</Button></>}
// 
