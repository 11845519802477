import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CreateUserPopup from "./Create"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DeleteWarningPopup from './DeleteWarningPopup';
import { url } from '../../constant';

const columns = [
  { id: 'id', label: 'Id', maxWidth: 50 },
  { id: 'whatsappNumber', label: 'Whatsapp Phone No Id', minWidth: 100, maxWidth: 130, format: (token) => token ? `${token.slice(0, 10)}...` : ""},
  { id: 'whatsappAccNumber', label: 'Whatsapp Account Id', minWidth: 100, maxWidth: 130, format: (token) => token ? `${token.slice(0, 10)}...` : ""},
  { id: 'msg_limit', label: 'Message Limit', minWidth: 100, maxWidth: 130},
  { id: 'allowed_app_user', label: 'No. of Devices Allowed', minWidth: 100, maxWidth: 130},
  { id: 'token', label: 'Whatsapp Token', minWidth: 100, maxWidth: 130, format: (token) => token ? `${token.slice(0, 10)}...` : ""},
  { id: 'email', label: 'Email', minWidth: 100, maxWidth: 150 },
  { id: 'name', label: 'Name', minWidth: 100, maxWidth: 150 },
  { id: 'otp_authentication', label: 'OTP Auth', minWidth: 100, maxWidth: 130 },
  { id: 'mobile_number', label: 'Mobile Numbner', minWidth: 100, maxWidth: 130 },
  { id: 'fileName', label: 'File Name', minWidth: 100, maxWidth: 130 },
  { id: 'schemeFileName', label: 'Scheme File Name', minWidth: 100, maxWidth: 130 },
  { id: 'stockFileName', label: 'Stock File Name', minWidth: 100, maxWidth: 130 },
  { id: 'createdDate', label: 'Created Date', minWidth: 100, maxWidth: 150, format: (date) => moment.utc(date).format('lll') },
  { id: 'status', label: 'Active Status', minWidth: 100, maxWidth: 150},
  // { id: 'actions', label: 'Actions', minWidth: 100, maxWidth: 150},
];

function createData(id, whatsappNumber, whatsappAccNumber, msg_limit, allowed_app_user, token, email, fileName, schemeFileName, stockFileName, createdDate, status, name, third_party_api, otp_authentication, access_allowed, mobile_number) {
  return {id, whatsappNumber, whatsappAccNumber, msg_limit, allowed_app_user, token, email, name, fileName, schemeFileName, stockFileName, createdDate, status, third_party_api, otp_authentication, access_allowed, mobile_number};
}

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [res, setRes] = React.useState(null);
  const [isDataLoading, setIsDataLoading] = React.useState(true)
  const [rows, setRows] = React.useState([])
  const [userPopup, setUserPopup] = React.useState(false)
  const [selectedRow, setSelectedRow] = React.useState(null)
  const [userId, setUserId] = React.useState(null)
  const [showWarning, setShowWarning] = React.useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const userDeleteHandler = (id) => {

    setShowWarning(true)
    setUserId(id)
  }

  React.useEffect(() => {
    if(localStorage.getItem("login"))
    setIsDataLoading(true)
    axios.get(`${url}/simistocks/list_users`, {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")).token}`}}).then((res) => {
        setRes(res.data)
        setIsDataLoading(false)
        setRows(res.data.map(user => createData(user.user__id, user.whatsapp_phone_no_id, user.whatsapp_account_id, user.msg_limit, user.allowed_app_user, user.whatsapp_token, user.user__email, user.file_name, user.scheme_file_name, user.stock_file_name, user.user__date_joined, user.user__is_active, user.user__first_name, user.third_party_api, user.otp_authentication, user.access_allowed, user.mobile_number)))
    }).catch(e=>{console.log(e);setIsDataLoading(false)})
  }, [])

  React.useEffect(() => {
    if(localStorage.getItem("login"))
    setIsDataLoading(true)
    axios.get(`${url}/simistocks/list_users`, {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")).token}`}}).then((res) => {
        setRes(res.data)
        setIsDataLoading(false)
        setRows(res.data.map(user => createData(user.user__id, user.whatsapp_phone_no_id, user.whatsapp_account_id, user.msg_limit, user.allowed_app_user, user.whatsapp_token, user.user__email, user.file_name, user.scheme_file_name, user.stock_file_name, user.user__date_joined, user.user__is_active, user.user__first_name, user.third_party_api, user.otp_authentication, user.access_allowed, user.mobile_number)))
    }).catch(e=>{console.log(e);setIsDataLoading(false)})
  }, [userPopup, showWarning])

  return (
    <div style={{display: "grid", gap: "1rem"}}>
        <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
            <Typography variant="h6" fontWeight={"bold"} gutterBottom>
                Users
            </Typography>
            <Button variant="contained" onClick={() => setUserPopup(true)}>Add User +</Button>
        </div>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: "75vh", whiteSpace: "nowrap" }}>
            <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                {columns.map((column) => (
                    <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, width: column.width }}
                    >
                    {column.label}
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows && rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                    return (
                    <TableRow hover style={{cursor: "pointer"}} role="checkbox" tabIndex={-1} key={row.code} onClick={() => {setUserPopup(true);setSelectedRow(row)}}>
                        {columns.map((column) => {
                        return (
                            <TableCell key={column.id} align={column.align}>
                                { 
                                  column.id === "actions" ? 
                                  <>
                                    {/* <EditIcon onClick={() => {setUserPopup(true);setSelectedRow(row)}} />
                                      &nbsp;&nbsp;&nbsp;
                                    <DeleteIcon onClick={() => userDeleteHandler(row.id)} /> */}
                                  </> 
                                  :  
                                    typeof row[column.id] === "boolean"  ? 
                                    row[column.id] ? 
                                      <CheckCircleIcon style={{color: "#4bae4f"}}/> 
                                    : <CancelIcon  style={{color: "#f34235"}} /> 
                                      : column['format'] ? 
                                        column.format(row[column.id]) 
                                        : row[column.id]
                                }
                            </TableCell>
                        );
                        })}
                    </TableRow>
                    );
                })}
            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Paper>
        {userPopup && <CreateUserPopup selectedRow={selectedRow} handleDelete={() => userDeleteHandler(selectedRow.id)} handleCancel={() => {setUserPopup(false);setSelectedRow(null)}}/>}
        {
          showWarning && <DeleteWarningPopup delete={() => {
            let payload = {"delete":true,"id":userId}
            axios.post(`${url}/simistocks/update_user`, payload, { headers: { "Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}` } }).then((res) => {
                setShowWarning(false)
              }).catch(e => {console.log(e)})
          }} handleCancel={() => {setShowWarning(false)}} />
        }
    </div>
  );
}
