import React, { useState, useEffect } from 'react';
import { Grid, Button, Modal, Box, Typography, ListItem, Drawer, useMediaQuery, useTheme, TableContainer, Table, TableHead, TableCell, TableBody, TableRow } from '@mui/material';
import DoneAll from '@mui/icons-material/DoneAll';
import Done from '@mui/icons-material/Done';
import moment from 'moment';
import axiosInstance from '../../http-common';
import noRecordsFound from "../UIComponents/no_records_found.png"
import DownloadButton from './DownloadButton';

const WhatsappChatSummary = () => {
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [messages, setMessages] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [messagesData, setMessagesData] = useState({});

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Checks if the screen is mobile-sized

    // Fetch messages from API and extract the dates
    const fetchMessages = async () => {
        try {
            const response = await axiosInstance.get('/simistocks/list_marketing_messages');
            const data = response.data;

            const datesList = Object.keys(data); // Extract the timestamps
            setDates(datesList); // Set available dates
            setMessagesData(data);
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };

    // Handle date selection
    const handleDateClick = (date) => {
        setSelectedDate(date);
        setMessages(messagesData[date]);
        if (isMobile) {
            setOpenDrawer(true); // Open drawer for mobile
        } else {
            setOpenModal(true); // Open modal for desktop
        }
    };

    // Close modal and drawer
    const handleCloseModal = () => setOpenModal(false);
    const handleCloseDrawer = () => setOpenDrawer(false);

    useEffect(() => {
        fetchMessages();
    }, []);

    const renderMessagesList = () => (
        <Grid container item gap={"1rem"} sx={{ p: "2%" }}>
            {messages.length > 0 ? (
                messages.map((msg, index) => (
                    <Grid
                        key={index}
                        container
                        item
                        xs={12}
                        className='wa-message'
                        sx={{ p: 0 }}
                    // justifyContent={msg.message_type === "received" ? "start" : "end"}
                    >
                        <Grid item maxWidth={"100% !important"}>
                            <Typography
                                sx={msg.message_type !== "received" ? { float: "right", position: "relative", pr: "40px" } : { float: "left" }}
                                align={msg.message_type === "received" ? "left" : "left"}
                                className={msg.message_type === "received" ? "left-msg msg" : "right-msg msg"}
                                maxWidth={"100%"}
                            >
                                {(msg.data?.image || msg.data?.video || msg.data?.document) && (
                                    <div style={{ marginTop: '10px' }}>
                                        {msg.data?.image && (
                                            <img
                                                src={msg.data?.image}
                                                alt="attachment"
                                                style={{ width: 'calc(100% + 30px)', marginBottom: '10px' }}
                                            />
                                        )}
                                        {msg.data?.video && (
                                            <video
                                                controls
                                                src={msg.data?.video}
                                                style={{ width: 'calc(100% + 30px)', marginBottom: '10px' }}
                                            />
                                        )}
                                        {msg.data?.document && (
                                            <DownloadButton fileUrl={msg.data?.document} fileName={msg.data?.document.split("/")[msg.data?.document.length - 1]} />
                                            // <a
                                            //     href={msg.data?.document}
                                            //     download
                                            //     style={{ display: 'block', textDecoration: 'underline', color: 'blue', marginBottom: '10px' }}
                                            // >
                                            //     Document
                                            // </a>
                                        )}
                                    </div>
                                )}

                                <span style={{ overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: msg.data?.message?.replaceAll("\\n", "<br/>") }}></span>

                            </Typography>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography className={"time-txt"}>
                                {moment(msg.datetime).format("DD MMM YY | hh:mm")}
                            </Typography>
                        </Grid> */}
                    </Grid>
                ))
            ) : (
                <Typography>No messages for this date.</Typography>
            )}
        </Grid>
    );

    return (
        <div style={{ padding: "3%" }}>
            <Grid container spacing={2} justifyContent={"start"}>
                {
                    dates.length > 0 ?
                        dates.map((date) => (
                            <TableContainer sx={{maxWidth: "40%"}}>
                                <Table>
                                    <TableHead sx={{bgcolor: "#e7e7e7"}}>
                                        <TableCell sx={{fontWeight: "bold"}}>
                                            Time: {date}
                                        </TableCell>
                                        <TableCell>
                                            <span style={{fontWeight: "bold"}}>Message:</span> {messagesData[date]?.[0]?.data?.message}
                                        </TableCell>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableCell sx={{fontWeight: "bold"}}>
                                                                Phone No.
                                                            </TableCell>
                                                            <TableCell sx={{fontWeight: "bold"}}>
                                                                Status
                                                            </TableCell>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                messagesData[date]?.map(data => <TableRow>
                                                                    <TableCell>
                                                                        {data.phone_no}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {data.status}
                                                                    </TableCell>
                                                                </TableRow>)
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ))
                        : (
                            <div style={{ display: "grid", justifyItems: "center", width: "100%" }}>
                                <img style={{ maxWidth: "100%", height: "100%" }} src={noRecordsFound} alt='img' />
                                <p>No record found for this user</p>
                            </div>
                        )
                }
            </Grid>

            {/* Modal for desktop */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <Grid container gap={"1rem"} sx={{ p: 4 }}>
                    <Typography variant="h6">Messages for {selectedDate}</Typography>
                    {renderMessagesList()}
                </Grid>
            </Modal>

            {/* Drawer for mobile */}
            <Drawer anchor="bottom" open={openDrawer} onClose={handleCloseDrawer}>
                <Grid container gap={"1rem"} sx={{ p: 2, height: '70vh' }} alignContent={"baseline"}>
                    <Typography variant="h6">Messages for {selectedDate}</Typography>
                    {renderMessagesList()}
                </Grid>
            </Drawer>
        </div>
    );
};

export default WhatsappChatSummary;
