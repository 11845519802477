import React, { useEffect, useState } from 'react'
import welcome from "./assets/welcome-image-2.png"
import Button from '@mui/material/Button';
import axiosInstance from './http-common';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

function Home(props) {
  // const [otp, setOtp] = useState({otp: "Long long message Long long message Long long message Long long message Long long message Long long message ", last_updated_time: "2000-00-00"})
  const [otp, setOtp] = useState({})
  const [loading, setLoading] = useState(false)

  const getCurrentGreeting = () => {
    let date = new Date()
    if(date.getHours() < 12 && date.getHours() > 4){
      return "Good Morning"
    } else if(date.getHours() < 16 && date.getHours() > 12){
      return "Good Afternoon"
    } else {
      return "Good Evening"
    }
  }

  const getOtp = () => {
    setLoading(true)
    axiosInstance.get("/simistocks/get_tally_otp?id="+JSON.parse(localStorage.getItem("login"))?.user_app_id)
    .then(res => {
      setOtp(res.data)
      setLoading(false)
    })
    .catch(err => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if(localStorage.getItem("login")) getOtp()
  }, [])

  const access_allowed = JSON.parse(localStorage.getItem("login"))?.access_allowed
  const adminUser = JSON.parse(localStorage.getItem("login")) ? JSON.parse(localStorage.getItem("login")).admin : false
  return (
    <div className='home-container'>
        <div style={{textAlign: "center", margin: "0rem 1rem"}}>
            <h1 className='welcome-texts'>Welcome <br/>{JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).name}</h1>
            {/* <h1 className='greeting-texts'>{getCurrentGreeting()}</h1> */}
            {
              process.env.REACT_APP_DEVICETYPE === "nativeApp" && 
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                  {
                    otp.otp ? <p>
                      <b dangerouslySetInnerHTML={{__html: otp.otp?.replaceAll("\n", "<br />")}}></b> <br/> 
                      <sub>
                        Last updated on {otp.last_updated_time}
                        &nbsp;&nbsp;
                        <span style={{cursor: "pointer"}} onClick={getOtp} >
                          {loading ? <CircularProgress sx={{marginBottom: "-5px"}} size={"1rem"}/> : <RefreshIcon style={{height: "1rem", marginBottom: "-5px"}}/>}
                        </span>
                      </sub>
                    </p> 
                    : 
                    <p>
                      Click refresh button to get the message
                      &nbsp;&nbsp;
                      <span style={{cursor: "pointer"}} onClick={getOtp} >
                        {loading ? <CircularProgress sx={{marginBottom: "-5px"}} size={"1rem"}/> : <RefreshIcon style={{height: "1rem", marginBottom: "-5px"}}/>}
                      </span>
                    </p>
                  }

              {/* {otp.otp ? <p><b>{otp.otp}</b> - Last updated on {otp.last_updated_time}</p> : <p>Click refresh button to get OTP</p>} &nbsp;&nbsp;<span style={{cursor: "pointer"}} onClick={getOtp} >{loading ? <CircularProgress size={"1rem"}/> : <RefreshIcon style={{height: "1rem"}}/>}</span> */}
            </div>}
            {(adminUser || access_allowed?.stock) && <Button onClick={() => props.setShowDataGrid(true)} variant='contained' sx={{backgroundColor: "#1976d2 !important", color: "#fff !important", borderRadius: "5px !important", padding: "8px 20px !important"}} >Stock Summary</Button>}
            <br/>
            {(adminUser || access_allowed?.whatsAppChat) && (process.env.REACT_APP_DEVICETYPE === "nativeApp") && <Button onClick={() => props.setShowMessagesTable(true)} variant='contained' sx={{backgroundColor: "#1976d2 !important", color: "#fff !important", borderRadius: "5px !important", padding: "8px 20px !important", mt: "10px !important"}} >Chat Summary</Button>}
        </div>
        <div style={{width: "100%", textAlign: "center"}}>
            <img width={"80%"} src={welcome} />
        </div>
    </div>
  )
}

export default Home