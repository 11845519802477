import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SelectChat = () => {

  return (
    <Box
      sx={{
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: 2,
        flex: 1
      }}
    >
      <Typography variant="h4" gutterBottom>
        No Chat Selected
      </Typography>
      <Typography variant="body1" color="textSecondary" paragraph>
        Please select a chat from the list to start messaging.
      </Typography>
    </Box>
  );
};

export default SelectChat;
