import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure, you want to delete?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will clear all your data from our database permanantly.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{display: "flex", justifyContent: "center"}}>
          <Button onClick={props.handleClose} variant="contained">Cancel</Button>
          <Button onClick={props.deleteHandler} variant="contained" color='error' autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
