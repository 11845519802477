import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SelectAutoComplete from "./SelectAutoComplete"
import TextField from '@mui/material/TextField';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const [filters, setFilters] = React.useState(props.filters)
  const [godownList, setGodownList] = React.useState([])
  const [stockItemList, setStockItemList] = React.useState([])
  const [stockGroupList, setStockGroupList] = React.useState([])
  const [categoryList, setCategoryList] = React.useState([])
  const [financeNameList, setFinanceNameList] = React.useState([])
  const [brandList, setBrandList] = React.useState([])
  const [warning, setWarning] = React.useState(false)

  const handleFilterSelect = (e) => {
    if(e.target.value === null){
      let filter = filters
      delete filter[e.target.name]
      setFilters(filter)
    }
    else setFilters({...filters, [e.target.name]: e.target.value})
  }

  const handleRangeChange = (e) => {
    setWarning(false)
    if(e.target.name === "min"){
      if(filters.totalRange && filters.totalRange.max){
        if(parseFloat(e.target.value) > parseFloat(filters.totalRange.max)){
          setWarning(true)
          return
        }
      }
    } else if(e.target.name === "max"){
      if(filters.totalRange && filters.totalRange.min){
        if(parseFloat(e.target.value) < parseFloat(filters.totalRange.min)){
          setWarning(true)
          return
        }
      }
    }
    setFilters({...filters, totalRange: { ...filters.totalRange, [e.target.name]: e.target.value}})
  }


    React.useEffect(() => {
      setFilters(props.filters)
    },[props.filters])
    
    React.useEffect(() => {
        let allGodown = props.apiData?.map((stock) => stock.K9)
        let set = new Set(allGodown)
        let distinctGodowns = [...set].filter(ob => ob != null)
        setGodownList(distinctGodowns)
    },[])

    React.useEffect(() => {
        let allStockItem = props.apiData?.map((stock) => stock.K6)
        let set = new Set(allStockItem)
        let distinctStockItems = [...set].filter(ob => ob != null)
        setStockItemList(distinctStockItems)
    },[])

    React.useEffect(() => {
        let allStockGroup = props.apiData?.map((stock) => stock.K7)
        let set = new Set(allStockGroup)
        let distinctStockGroup = [...set].filter(ob => ob != null)
        setStockGroupList(distinctStockGroup)
    },[])

    React.useEffect(() => {
        let allCategory = props.apiData?.map((stock) => stock.K15)
        let set = new Set(allCategory)
        let distinctCategory = [...set].filter(ob => ob != null)
        setCategoryList(distinctCategory)
    },[])

    React.useEffect(() => {
        let allFinanceName = props.apiData?.map((stock) => stock.K12)
        let set = new Set(allFinanceName)
        let distinctFinanceNames = [...set].filter(ob => ob != null)
        setFinanceNameList(distinctFinanceNames)
    },[])

    React.useEffect(() => {
        let allBrand = props.apiData?.map((stock) => stock.K13)
        let set = new Set(allBrand)
        let distinctBrands = [...set].filter(ob => ob != null)
        setBrandList(distinctBrands)
    },[])

  const handleClose = () => {
    props.setFiltersOpen(false);
  };

  const handleSave = () => {
    props.setFilters(filters)
    props.setFiltersOpen(false);
  }

  return (
    <div>
      <Dialog
        // fullScreen
        open={props.filtersOpen}
        onClose={handleClose}
        // TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', background: "#d4f4fb", color: "#000" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Filters
            </Typography>
            <Button autoFocus color="inherit" variant={"contained"} style={{border: "1px solid #fff", padding: "0.4rem 1rem", background: "#fff", color: "rgb(18, 26, 78)", fontWeight: "bold"}} onClick={handleSave}>
              apply
            </Button>
          </Toolbar>
        </AppBar>
        <List sx={{maxHeight: {xs: "400px", md: "max-content"}, overflowY: "scroll"}}>
          <ListItem>
            <FormControl fullWidth>
              <SelectAutoComplete data={godownList} value={filters.godown} label="Godown" handleFilterSelect={handleFilterSelect} name="godown" />
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <SelectAutoComplete data={stockItemList} value={filters.stockItem} label="Stock Item" handleFilterSelect={handleFilterSelect} name="stockItem" />
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <SelectAutoComplete data={stockGroupList} value={filters.stockGroup} label="Stock Group" handleFilterSelect={handleFilterSelect} name="stockGroup" />
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <SelectAutoComplete data={categoryList} value={filters.category} label="Category" handleFilterSelect={handleFilterSelect} name="category" />
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <SelectAutoComplete data={financeNameList} value={filters.financeName} label="Finance Name" handleFilterSelect={handleFilterSelect} name="financeName" />
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <SelectAutoComplete data={brandList} value={filters.brand} label="Brand" handleFilterSelect={handleFilterSelect} name="brand" />
            </FormControl>
          </ListItem>
          <ListItem style={{flexDirection: "column", alignItems: "flex-start"}}>
            <FormControl fullWidth style={{display: "flex", flexDirection: "row", width: "100%", gap: "1rem", alignItems: "center"}}>
              <TextField name='min' label="Min Total" onChange={handleRangeChange} />
              <CompareArrowsIcon />
              <TextField name='max' label="Max Total" onChange={handleRangeChange} />
            </FormControl>
            {warning && <p style={{color: "red"}}>Min total must be lesser than Max total</p>}
          </ListItem>
        </List>

      </Dialog>
    </div>
  );
}
