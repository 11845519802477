const columnsFormat = [
    { id: 'K1', label: 'Date', minWidth: 120, align: 'left', format: (value) => <p style={{width: "8rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#0b88fe"}}>{value}</p>,  },
    { id: 'K4', label: 'Customer Name', minWidth: 120, align: 'left', format: (value) => <p style={{width: "12rem",fontWeight: 500, color: typeof value === "string" && typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    { id: 'K6', label: 'Stock Item', minWidth: 200, align: 'left', format: (value) => <p style={{width: "12rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    { id: 'K9', label: 'Godown', minWidth: 80, align: 'left', format: (value) => <p style={{width: "10rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    { id: 'K10', label: 'Quantity', minWidth: 120, align: 'center', format: (value) => <p style={{width: "2rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    { id: 'K11', label: 'Total', minWidth: 120, align: 'left', format: (value) => <p style={{width: "5rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    { id: 'Exchange', label: 'Exchange Value', minWidth: 120, align: 'left', format: (value) => <p style={{width: "5rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    { id: 'K12', label: 'Finance Name', minWidth: 120, align: 'left', format: (value) => <p style={{textAlign: "left", width: "8rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    { id: 'K8', label: 'IMEI Number', minWidth: 200, align: 'left', format: (value) => <p style={{width: "9rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#0b88fe"}}>{value}</p>, },
    { id: 'K13', label: 'Brand', minWidth: 120, align: 'left', format: (value) => <p style={{textAlign: "left", width: "8rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    { id: 'K14', label: 'Salesman', minWidth: 120, align: 'left', format: (value) => <p style={{width: "10rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    { id: 'K2', label: 'VCH Type', minWidth: 120, align: 'left', format: (value) => <p style={{width: "8rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>,  },
    { id: 'K3', label: 'Invoice Number', minWidth: 120, align: 'left', format: (value) => <p style={{width: "8rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#0b88fe"}}>{value}</p>, },
    { id: 'K5', label: 'Contact', minWidth: 120, align: 'left', format: (value) => <p style={{width: "8rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    { id: 'K7', label: 'Stock Group', minWidth: 200, align: 'left', format: (value) => <p style={{width: "12rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    { id: 'K15', label: 'Category', minWidth: 200, align: 'left', format: (value) => <p style={{width: "8rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    { id: 'K16', label: 'Stock Alias', minWidth: 200, align: 'left', format: (value) => <p style={{width: "8rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    // { id: 'K17', label: 'File Name', minWidth: 200, align: 'left', format: (value) => <p style={{width: "12rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    // { id: 'K18', label: 'DOB', minWidth: 200, align: 'left', format: (value) => <p style={{width: "12rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    // { id: 'K19', label: 'PDF File Name', minWidth: 200, align: 'left', format: (value) => <p style={{width: "12rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
    // { id: 'id', label: 'Id', minWidth: 200, align: 'left', format: (value) => <p style={{width: "12rem",fontWeight: 500, color: typeof value === "string" && value.toLowerCase() === "no" ? "#ff4646" : "#393939"}}>{value}</p>, },
  ];

  export default columnsFormat;