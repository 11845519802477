import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import { url } from '../../constant';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

function DefaultData(props) {
  
    const [isSendInProgress, setIsSendInProgress] = useState(false)
    const [imageName, setImageName] = useState("")
    const [image, setImage] = useState("");
    const [imageFile, setImageFile] = useState("")
    const [texts, setTexts] = useState("")
    const [success, setSuccess] = useState(false)

    const onImageChange = (e) => {

        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        setImageFile(e.target.files[0])
        reader.onload = () => {
          const  fileType = e.target.files[0]['type'];
          const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
          if (validImageTypes.includes(fileType)) {
            setImage(reader.result)
          }
        }
        
      }

  const handleSend = () => {

    let formData = new FormData()
    if(imageFile) formData.append("image", imageFile, imageFile.name)
    if(texts) formData.append("msg", texts)

    setIsSendInProgress(true)
    axios.post(`${url}/simistocks/default_data`, formData, {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")).token}`}}).then((res) => {
        setIsSendInProgress(false)
        setSuccess(true)
    }).catch(e=>{console.log(e);setIsSendInProgress(false)})
  }

  const deleteDefault = () => {
    let formData = new FormData()
    formData.append("image", "")
    formData.append("msg", "")
    axios.post(`${url}/simistocks/default_data`, formData, {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")).token}`}})
    .then(() => getData())
  }

  const getData = () => {
    axios.get(`${url}/simistocks/get_default_data`, {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")).token}`}}).then(res => {
      setImage(res.data.img)
      setTexts(res.data.msg)
    })
    return () => {
      setSuccess(false)
    }
  }

  useEffect(() => {

    getData()
  },[])

  return (<>
    <div style={{margin: "auto", marginTop: "0rem", maxWidth: "600px"}}>
        <DialogTitle style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}><div>Add default image and texts</div><DeleteIcon onClick={deleteDefault} style={{cursor: "pointer"}} /></DialogTitle>
        <DialogContent sx={{width: "100%"}} style={{overflowX: "hidden", gap: "0.5rem", display: "grid", justifyItems: "center"}}>
            {image && <img style={{height: "13rem"}} src={image} />}
            <label style={{width: "100%"}} class="file-upload">
                <CloudUploadIcon />
                <p>{imageName || "Upload Image"}</p>
                <input type="file" onChange={onImageChange}/>
            </label>
            <textarea
                name="texts"
                value={texts}
                style={{width: "100%"}}
                onChange={(e) => setTexts(e.target.value)}
                rows={5}
              />
        </DialogContent>
        <DialogActions style={{display: "flex", justifyContent: "center"}}>
          <Button variant="outlined" style={{border: "1px solid rgba(25, 118, 210, 0.5)", color: "#1976d2"}} onClick={props.handleCancel}>Cancel</Button>
          <Button variant='contained' style={{backgroundColor: "#1976d2"}} onClick={handleSend}>
            {!isSendInProgress ? "Done" :
              <CircularProgress style={{color: "#fff", opacity: "0.7", height: "2rem", width: "2rem"}} />}
            </Button>
        </DialogActions>
      </div>
      <Dialog open={success} sx={{overflowX: "hidden"}} PaperProps={{style: {minWidth: "350px"}}}>
        <DialogContent sx={{width: "100%", maxHeight: "300px"}} style={{overflowX: "hidden", gap: "0.5rem", display: "grid", justifyItems: "center"}}>
          <CheckCircleOutlineIcon style={{height: "10rem", width: "8rem", color: "green"}} />
          <p style={{fontSize: "1.3rem"}}>Success</p>          
        </DialogContent>
        <DialogActions style={{display: "flex", justifyContent: "center"}}>
          <Button variant="outlined" style={{border: "1px solid rgba(25, 118, 210, 0.5)", color: "#1976d2"}} onClick={props.handleCancel}>Close</Button>
        </DialogActions>
      </Dialog>
      </>
  )
}

export default DefaultData