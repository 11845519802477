
// exchane_wp_msg
import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import columns from "../../columnFormat"
// import { downloadCSV } from 'react-admin';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import jsonExport from 'jsonexport/dist';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { makeStyles } from '@mui/styles';
import { Button, Grid } from '@mui/material';
import moment from 'moment';
import noRecordsFound from "./no_records_found.png"
import { Bars } from 'react-loader-spinner';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WhatsAppPopup from '../User/WhatsApp';
import axios from 'axios';
import WhatsAppMessageResultsDialoge from '../User/WhatsAppMessageResultsDialoge';
import FilterListIcon from '@mui/icons-material/FilterList';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FilledInput from '@mui/material/FilledInput';
import AlertDialog from './ConfirmationDialog';
import { url } from '../../constant';
import { useState } from 'react';
import csvDownload from 'json-to-csv-export'
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { formatIndianNumber } from '../../utils';

const useStyles = makeStyles({
  // datePicker: {cursor: "pointer", position: "absolute", zIndex: 9999, top: "4rem", boxShadow: "2px 2px 11px", right: "6rem", display: "grid", justifyItems: "center", background: "#fff"},
  // applyButton: {padding: "8px", width: "max-content", margin: "10px", background: "#006eff", color: "#fff", border: "none", borderRadius: "5px", fontWeight: "bold"},
  // loader: {justifyContent: "center", height: 400, alignItems: 'center'}
})

function createData(K1, K2, K3, K4, K5, K6, K7, K8, K9, K10, K11, K12, K13, K14, K15, K16, K17, K18, K19, ec, id) {
    return { K1, K2, K3, K4, K5, K6, K7, K8, K9, K10, K11, K12, K13, K14, K15, K16, K18, K19};
  }

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const exporter = (selectedStocks, stocks, setLoader) => {
  // let head = columns.map(headCell => {return { : }})
  // console.log("selectedStocks", selectedStocks, stocks);
  let stockForExport = []
  const stocksForExport = selectedStocks.map(selectedStock => {
      for(let i in stocks){
        if(`${stocks[i].K4}_${stocks[i].K8}` === selectedStock){
          // console.log("stocks[i]", stocks[i]);
          let {K1, K2, K3, K4, K5, K6, K7, K8, K9, K10, K11, K12, K13, K14, K15, K16, K17, K18, K19} = stocks[i]
          stockForExport.push({"Date": K1, "VCH Type": K2, "Invoice Number": K3, "Customer Name": K4, 
          "Contact": K5, "Stock Item": K6, "Stock Group": K7, "IMEI Number": K8, "Godown": K9, "Quantity": K10, "Total": K11, 
          "Finance Name": K12, "Brand": K13, "Salesman": K14, "Category": K15, "Stock Alias": K16, "DOB": K18, "PDF File Name": K19})
        }
      }
      return stockForExport
  });

  const dataToConvert = {
    data: stockForExport,
    filename: 'stock-data',
    delimiter: ',',
    headers: ['Date', "VCH Type", "Invoice Number", "Customer Name", "Contact", "Stock Item", "Stock Group", "IMEI Number", "Godown",
              "Quantity", "Total", "Finance Name", "Brand", "Salesman", "Category", "Stock Alias"]
  }

// console.log("stockForExport", dataToConvert);
  csvDownload(dataToConvert)
  setLoader(false)


  // jsonExport(stockForExport, {}, (err, csv) => {
  //     downloadCSV(csv, 'stocks');
  // });
};

const filterNumbers = (resData, setNumberList, setShowWhatsAppPopup) => {
  // let numberList = []

  // console.log("resData", resData)

  // setNumberList(numberList)
  setShowWhatsAppPopup(true)
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return <TableHead>
          <TableRow>
            {/* <TableCell width={2} />
            <TableCell width={100}></TableCell> */}
          </TableRow>
        </TableHead>

  // return (
  //   <TableHead>
  //     <TableRow>
  //       <TableCell padding="checkbox">
  //         <Checkbox
  //           color="primary"
  //           indeterminate={numSelected > 0 && numSelected < rowCount}
  //           checked={rowCount > 0 && numSelected === rowCount}
  //           onChange={onSelectAllClick}
  //           onClick={(event) => {
  //             if(event.target.checked){
  //               props.setSelected([])
  //               numberList = []
  //               // console.log("new sesl");
  //             } 
  //             props.rows.map((row) => {if(row.K8 !== "No"){props.handleClick(event, `${row.K4}_${row.K8}`, row);props.handleNumberList(row.id, row.K5, row)}})}}
  //           inputProps={{
  //             'aria-label': 'select all desserts',
  //           }}
  //         />
  //       </TableCell>
  //       {columns.map((headCell) => (
  //         headCell.id === "Exchange" ? numSelected > 0 ? 
  //         <TableCell
  //           key={headCell.id}
  //           align={headCell.numeric ? 'right' : 'left'}
  //           padding={headCell.disablePadding ? 'none' : 'normal'}
  //           sortDirection={orderBy === headCell.id ? order : false}
  //         >
  //           <TableSortLabel
  //             active={orderBy === headCell.id}
  //             direction={orderBy === headCell.id ? order : 'asc'}
  //             onClick={createSortHandler(headCell.id)}
  //           >
  //             {headCell.label}
  //             {orderBy === headCell.id ? (
  //               <Box component="span" sx={visuallyHidden}>
  //                 {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
  //               </Box>
  //             ) : null}
  //           </TableSortLabel>
  //         </TableCell> : <></>
  //         :
  //         <TableCell
  //           key={headCell.id}
  //           align={headCell.numeric ? 'right' : 'left'}
  //           padding={headCell.disablePadding ? 'none' : 'normal'}
  //           sortDirection={orderBy === headCell.id ? order : false}
  //         >
  //           <TableSortLabel
  //             active={orderBy === headCell.id}
  //             direction={orderBy === headCell.id ? order : 'asc'}
  //             onClick={createSortHandler(headCell.id)}
  //           >
  //             {headCell.label}
  //             {orderBy === headCell.id ? (
  //               <Box component="span" sx={visuallyHidden}>
  //                 {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
  //               </Box>
  //             ) : null}
  //           </TableSortLabel>
  //         </TableCell>
  //       ))}
  //     </TableRow>
  //   </TableHead>
  // );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  // const [datesRange, setDatesRange] = React.useState("")
  const classes = useStyles()
  const [datePopupOpen, setDatePopupOpen] = React.useState(false)
  const [startDate, setStartDate] = React.useState(props.datesRange[0].startDate)
  const [endDate, setEndDate] = React.useState(props.datesRange[0].endDate)
  const [searchQuery, setSearchQuery] = React.useState("")
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  const [datesRange, setDatesRange] = React.useState(
    {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      key: 'selection'
    }
  );

  const toggleDatePicker = () => {
    setDatePopupOpen(!datePopupOpen)
  }

  const deleteHandler = () => {
    axios.post(`${url}/simistocks/delete_data`,
      {},
      // {"phone_numbers":numberLs}, 
      {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}`}})
      .then(res => {
       setAlertOpen(false)
       props.setRes([])
      })
      .catch(error => console.log(error.message))
  }


  React.useEffect(() => {
    if(props.datesRange[0].startDate){
      setStartDate(moment(props.datesRange[0].startDate))
    }
    if(props.datesRange[0].endDate){
      setEndDate(moment(props.datesRange[0].endDate))
    }
  }, [props.datesRange])
  let direction = window.screen.width < 480 ? "column" : ""
  let align = window.screen.width < 480 ? "center" : ""
  return (
    <>
      <Toolbar
        sx={{
          pl: { xs: 0, sm: 2 },
          pr: { xs: 0, sm: 1 },
          flexDirection: "row",
          alignItems: align,
          flexWrap: "wrap",
          justifyContent: "space-between",
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected === 0 && <form onSubmit={(e) => {e.preventDefault();props.setFilters({...props.filters, searchQuery: searchQuery})}} style={{display: "flex", width: "90%"}}>
          <FormControl sx={{ m: 1, width: '100%' }} variant="filled">
            <InputLabel style={{top: "-7px"}} htmlFor="filled-adornment-password">Search</InputLabel>
            <FilledInput
              style={{height: "50px", border: "1px solid #121a4e", borderRadius: "5px", cursor: "pointer", background: "#fff", color: "rgb(18, 26, 78)"}}
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton type='submit' style={{background: "#000 !important"}}><SearchIcon /></IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </form>}
        {numSelected === 0 && <div style={{display: "flex", justifyContent: "center"}}>
          {/* <Tooltip title="Delete Data">
            <IconButton sx={{p: "10px 3px"}} onClick={() => setAlertOpen(true)}>
              <DeleteIcon style={{color: "#121a4e"}} />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Filters">
          <IconButton sx={{p: "10px 3px"}} onClick={() => props.setFiltersOpen(true)}>
              <FilterListIcon style={{color: "#121a4e"}} />
            </IconButton>
          </Tooltip>
          </div>
        }
        {props.selected.length > 0 && <Tooltip title="Export">
          <IconButton onClick={() => {setLoader(true);exporter(props.selected, props.res, setLoader)}}>
            {loader ? <CircularProgress /> : <FileDownloadIcon />}
          </IconButton>
        </Tooltip>}
        {numSelected === 0 && <div style={{width: "100%", display: "flex", padding: "0px 6px"}}>
          <form style={{padding: "8px", width: (props.showFilters || datesRange.startDate || datesRange.endDate) ? "80%" : "100%"}} onSubmit={e => {
            e.preventDefault()
            props.setDatesRange([{startDate: datesRange.startDate, endDate: datesRange.endDate}])
          }}>
              <input style={{border: "1px solid #121a4e", borderRadius: "5px 0px 0px 5px", cursor: "pointer", padding: "0.3rem 0.6rem", background: "#fff", color: "rgb(18, 26, 78)", fontWeight: "bold", width: "38%"}} onChange={(e => setDatesRange({...datesRange, startDate: e.target.value}))} value={datesRange.startDate} type={"date"} placeholder="dd/mm/yyyy" />
              <input style={{border: "1px solid #121a4e", cursor: "pointer", padding: "0.3rem 0.6rem", background: "#fff", color: "rgb(18, 26, 78)", fontWeight: "bold", width: "38%"}} onChange={(e => setDatesRange({...datesRange, endDate: e.target.value}))} value={datesRange.endDate} type={"date"} />
              <input style={{border: "1px solid #121a4e", borderRadius: "0px 5px 5px 0px", cursor: "pointer", padding: "0.38rem 0.6rem", background: "#fff", color: "rgb(18, 26, 78)", fontWeight: "bold", width: "24%"}} type={"submit"} />
          </form>
          {(props.showFilters || datesRange.startDate || datesRange.endDate ) && 
            <Button 
              onClick={() => {props.clearFilter({});setSearchQuery("");setDatesRange({startDate: "", endDate: ""});}} 
              style={{
                border: "1px solid #121a4e", 
                borderRadius: "5px", 
                cursor: "pointer", 
                padding: "0px 10px", 
                background: "#fff", 
                color: "rgb(18, 26, 78)", 
                fontWeight: "bold", 
                marginTop: "7px", 
                height: "30px",
                textDecoration: "line-through"
              }}>
            Filters
          </Button>}
        </div>}
        {numSelected > 0 && (
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item>
              <Typography
                // sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="WhatsApp">
                <IconButton onClick={() => {filterNumbers(props.selected, props.setNumberList, props.setShowWhatsAppPopup); props.exchangeList.length>0 && props.setHideDropdown(true)}}>
                  <WhatsAppIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        
      </Toolbar>
      <AlertDialog deleteHandler={deleteHandler} handleClose={() => setAlertOpen(false)} open={alertOpen} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


let numberList = []

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showWhatsAppPopup, setShowWhatsAppPopup] = React.useState(false)
  const [hideDropdown, setHideDropdown] = React.useState(false)
  // const [numberList, setNumberList] = React.useState([])
  const [whatsAppMessageResults, setWhatsAppMessageResults] = React.useState(null)
  const [total, setTotal] = React.useState(0)
  const [quantity, setQuantity] = React.useState(0)
  const [exchangeList, setExchangeList] = useState([])

  const [isSendInProgress, setSendInProgress] = React.useState(false)
  const rows = groupObjectsByKey(props.res, "K6")

  const handleExchange = (numberLs, data, imageFile, additionalTexts, exchangeTexts) => {

    // var longArray = [{ "K1": "1-4-2021", "K2": "Pos Sales - N1", "K3": "N1/21-22/1", "K4": "Rajesh Singh", "K5": "7440855210", "K6": "VIVO Y20A 2021 3/64GB Dawn White", "K7": "HS - VIVO Y20A 2021 3+64GB", "K8": "864419055352253", "K9": "Shop - N1", "K10": 1, "K11": 11100, "K12": "No", "K13": "No", "K14": "Sm Amit Das", "K15": "HS - Smart Phones", "K16": "6935117831732", "K17": "111KAPIL", "id": "1", "row": "row_1" }]// [...exchangeList];
    var longArray = [...exchangeList];
  
    var size = 1;
    var count = 0;
    var result = {}

    let formData = new FormData()
    imageFile && formData.append("image", imageFile, imageFile.name);
    formData.append("free_field_msg", exchangeTexts.replace(/\n/g, '\\n'));
    formData.append("template_data", JSON.stringify(data));

    // var newArray = [[{ "K1": "1-1-2022", "K2": "Pos Sales - N1", "K3": "N1/21-22/5004", "K4": "Jamil Ahmad", "K5": "7440855210", "K6": "XIAOMI REDMI 9i 4/64GB Green", "K7": "HS - XIAOMI REDMI 9i 4+64GB", "K8": "869805051979609", "K9": "Shop - N1", "K10": 1, "K11": 8999, "K12": "No", "K13": "No", "K14": "Sm Rahul", "K15": "HS - Smart Phones", "K16": "8908012587668", "K17": "4488BABLOOSALES", "id": "103", "row": "row_103", "exchange_value": "8000" }]]
    var newArray = new Array(Math.ceil(longArray.length / size)).fill("")
        .map(function() { return this.splice(0, size) }, longArray.slice());

        newArray?.forEach((arr, i) => {

    formData.append("data", JSON.stringify(arr))
    axios.post(`${url}/simistocks/exchange_wp_msg`,
      formData,
        // {"phone_numbers":numberLs}, 
        {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")) && JSON.parse(localStorage.getItem("login")).token}`}})
        .then(res => {
          count++
          result = {...result, ...res.data}
          if(count === newArray.length) {
            setWhatsAppMessageResults(result)
            setSendInProgress(false)
          }
        })
        .catch(error => {console.log(error.message); setSendInProgress(false);});

        formData.delete("data")
      })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    let total = 0;
    let quantity = 0;
    
    if(props.res){
      for(let i=0; i<props.res.length; i++){
        if(!isNaN(props.res[i].K11)) total = total + props.res[i].K11
        if(!isNaN(props.res[i].K10)) quantity = quantity + props.res[i].K10
      }

      setTotal(total)
      setQuantity(quantity)
    }
  },[props.res])

  React.useEffect(() => {
    if(selected.length === 0){
      setHideDropdown(false)
      setExchangeList([])
    }
  },[selected])

  React.useEffect(() => {
    setRowsPerPage(10)
    setPage(0)
  }, [props.filters])
  

  // const isSelected = (name) => selected.indexOf(name) !== -1;
  const isSelected = (name) => exchangeList.some(row => name === `${row.K4}_${row.K8}`);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const ROWS = Object.keys(rows)
  // const ROWS1 = stableSort(Object.values(rows), getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  const ROWS1 = props.res?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  return (
    <Box>
      <Paper sx={{ width: '100%', mb: 2, maxHeight: "max-content", height: "max-content", }}>
        <EnhancedTableToolbar setRes={props.setRes} filters={props.filters} setFilters={props.setFilters} showFilters={Object.keys(props.filters).length > 0} clearFilter={props.clearFilter} setFiltersOpen={props.setFiltersOpen} setShowWhatsAppPopup={setShowWhatsAppPopup} setHideDropdown={setHideDropdown} rows={rows} selected={selected} res={props.res} numSelected={exchangeList.length} setDatesRange={props.setDatesRange} exchangeList={exchangeList} datesRange={props.datesRange}/>
        <Box display="flex" gap="1rem" justifyContent="center" padding={{xs: "8px", md: "16px"}} pb={{xs: "12px !important", md: "24px"}}>
          <h3 style={{margin: "4px 0px"}}>Quantity: {formatIndianNumber(quantity)}</h3>
          <h3 style={{margin: "4px 0px"}}>Total: {formatIndianNumber(parseFloat(total).toFixed(2))}</h3>
        </Box>
        <TableContainer sx={{maxHeight: "66vh", height: "66vh", minHeight: props.isDataLoading ? 400 : "max-content"}}>
          <Table
            aria-labelledby="tableTitle"
            size={'medium'}
            stickyHeader
            className='sticky-first-column'
            style={{maxHeight: "70vh", height: "max-content"}}
          >
           {ROWS.length > 0 ? <>
            <TableHead>
                <TableRow>
                  {/* <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align='center'></TableCell> */}
                  {/* <TableCell align='center'>Total</TableCell> */}
                </TableRow>
              </TableHead>
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
           {console.log("ROWS", ROWS, ROWS1, props.res)}
              {
              // stableSort(ROWS1, getComparator(order, orderBy))
              //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ROWS1
                .map((row, index) => {
                  return <Row key={ROWS1[index]} rowKey={ROWS1[index]} title={ROWS[index]} row={ROWS1[index]} />
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody></> : 
            props.isDataLoading ? 
            <TableBody>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Bars wrapperStyle={{justifyContent: "center", height: 400, alignItems: 'center'}} color="rgb(18, 26, 78)" height={80} width={80} /> 
                    </TableCell>
                </TableRow>
              </TableBody>
            :
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9}>
                    <div style={{display: "grid", justifyItems: "center"}}>
                      <img style={{width: "100%", maxWidth: "700px"}} src={noRecordsFound} alt='img' />
                      {false ? <p>No record found with selected date range, please try changing range</p>
                      : <p>No record found for this user</p>}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
              }
          </Table>
        </TableContainer>
        {ROWS.length > 0 && <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={ROWS.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '.MuiTablePagination-toolbar': {
              padding: "0px 10px"
            },
            '.MuiTablePagination-actions': {
              m: "0px 10px"
            },
            '.MuiInputBase-root': {
              m: "0px 10px"
            },
          }}
        />}
      </Paper>
      {
        showWhatsAppPopup && !whatsAppMessageResults && <WhatsAppPopup hideDropdown={hideDropdown} isSendInProgress={isSendInProgress} numberList={[...new Set(numberList.map(number => number.split("_")[1]))]} handleCancel={() => {setShowWhatsAppPopup(false);setHideDropdown(false)}} handleSend={handleExchange}  />
      }
      {
        whatsAppMessageResults && <WhatsAppMessageResultsDialoge handleDone={() => {setWhatsAppMessageResults(null); setShowWhatsAppPopup(false)}} whatsAppMessageResults={whatsAppMessageResults} />
      }
    </Box>
  );
}


function groupObjectsByKey(objects, key) {
  const groupedObjects = {};

  objects?.forEach(obj => {
      if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (!groupedObjects[value]) {
              groupedObjects[value] = [];
          }
          groupedObjects[value].push(obj);
      }
  });

  return groupedObjects;
}

function Row(props) {
  const { row, rowKey } = props;
  const [open, setOpen] = React.useState(false);

  // console.log("row", row);

  return (
    <React.Fragment key={rowKey}>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
        {/* <TableCell width={2} style={{padding: 3}}>
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}
        <TableCell colSpan={3} style={{padding: 3}} width={350} component="th" scope="row">
          <Grid container border="1px solid #898989" borderRadius="5px">
            <Grid container item xs={12} bgcolor={"#d4f4fb45"} padding="5px 10px">
                {row.K6}
            </Grid>
            <hr style={{width: "100%", margin: "0px"}}/>
            <Grid container item xs={12} padding="5px 10px">
              <Grid item xs={6}>{row.K9}</Grid> {/* Godown */}
              <Grid item xs={3} textAlign={"center"}>{row.K10}</Grid> {/* Qty */}
              <Grid item xs={3} textAlign={"right"}>{row.K11}</Grid> {/* Amount */}
              <Grid item xs={6}>{row.K14}</Grid> {/* Salesman */}
              <Grid item xs={6} textAlign={"right"}>{row.K12}</Grid> {/* Finance Name */}
              <Grid item xs={6}>{row.K1}</Grid> {/* Date */}
              <Grid item xs={6} textAlign={"right"}>{row.K3}</Grid> {/* Invoice */}
            </Grid>
          </Grid>
        </TableCell>
        {/* <TableCell style={{padding: 3}} width={350} align='center'>
          {row.K11}
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableCell colSpan={3}>
              <TableBody>
                  <React.Fragment key={row.K3}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Quantity
                      </TableCell>
                      <TableCell align="right">{row.K10}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Total
                      </TableCell>
                      <TableCell align="right">{row.K11}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Customer Name
                      </TableCell>
                      <TableCell align="right">{row.K4}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        IMEI Number
                      </TableCell>
                      <TableCell align="right">{row.K8}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Godown
                      </TableCell>
                      <TableCell align="right">{row.K9}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Finance Name
                      </TableCell>
                      <TableCell align="right">{row.K12}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Brand
                      </TableCell>
                      <TableCell align="right">{row.K13}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Salesman
                      </TableCell>
                      <TableCell align="right">{row.K14}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Date
                      </TableCell>
                      <TableCell align="right">{row.K1}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        VCH Type
                      </TableCell>
                      <TableCell align="right">{row.K2}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Invoice Number
                      </TableCell>
                      <TableCell align="right">{row.K3}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Contact
                      </TableCell>
                      <TableCell align="right">{row.K5}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Stock Group
                      </TableCell>
                      <TableCell align="right">{row.K7}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Category
                      </TableCell>
                      <TableCell align="right">{row.K15}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Stock Alias
                      </TableCell>
                      <TableCell align="right">{row.K16}</TableCell>
                    </TableRow>
                  </React.Fragment>
              </TableBody>
            </TableCell>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
