import axiosInstance from "../http-common.js";

const login = (body) => {
    return axiosInstance.post(`login/`, body);
};

const otpVerify = (body) => {
    return axiosInstance.post(`simistocks/validate_otp`, body);
};

const getSalesData = (body) => {
    return axiosInstance.get(`simistocks/simidata`);
};

const getStocksData = (body) => {
    return axiosInstance.get(`simistocks/simistocksdata`);
};

const AuthDataService = {
    login,
    getSalesData,
    getStocksData,
    otpVerify
};

export default AuthDataService;