import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import WhatsAppList from "./WhatsAppList"
import WhatsappTemplate from "./WhatsappTemplate"
import { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ImageUploading from "./ImageUploading"
import UploadFileIcon from '@mui/icons-material/UploadFile';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { url } from '../../constant';

function WhatsAppPopup(props) {
  let numberList = [...new Set(props.numberList)]
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [templateList, setTemplateList] = useState(JSON.parse(localStorage.getItem("login")).templates)
  const [messageLimit, setMessageLimit] = useState(0);
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("")
  const [isImage, setIsImage] = useState(false)
  const [imageFile, setImageFile] = useState("")
  const [numbers, setNumbers] = useState("")
  const [additionTexts, setAdditionTexts] = useState("")
  const [exchangeTexts, setExchangeTexts] = useState("")
  const [buttonUrl, setButtonUrl] = useState("")
  const ignoreList = ["hello_world","sample_purchase_feedback","sample_happy_hour_announcement","sample_shipping_confirmation","sample_movie_ticket_confirmation","sample_issue_resolution", "sample_flight_confirmation"]

  useEffect(() => {
    axios.get(`${url}/simistocks/templates`, {headers: {"Authorization": `Token ${JSON.parse(localStorage.getItem("login")).token}`}}).then((res) => {
        setTemplateList(res.data.data)
        setMessageLimit(res.data.msg_limit)
        if(props.showDefault){
          let text_with_image = res.data.data.filter(t => t.name.includes("text_with_image"))[0]
          let only_text = res.data.data.filter(t => t.name.includes("only_text"))[0]
          if(text_with_image.default_file && text_with_image.default_text) setSelectedTemplate(text_with_image)
          else if(!text_with_image.default_file && text_with_image.default_text) setSelectedTemplate(only_text)
          // else if(text_with_image.default_file && !text_with_image.default_text) setSelectedTemplate(text_with_image)
        } else {
          setSelectedTemplate()
          setSelectedTemplate()
        }
    }).catch(e=>{console.log(e)})

    setSelectedTemplate()
    setSelectedTemplate()

  },[props.showDefault])

  useEffect(() => {
  // console.log("selectedTemplate", selectedTemplate);
    if(props.showDefault) setAdditionTexts(selectedTemplate?.default_text)
  },selectedTemplate)

  useEffect(() => {
    if(props.whatsAppMessageResults){
      if(props.showDefault){
        setNumbers("")
        return
      }
      setNumbers("")
      setSelectedTemplate(null)
      setImage("");
      setImageName("")
      setIsImage(false)
      setImageFile("")
      setNumbers("")
      setAdditionTexts("")
      setExchangeTexts("")
    }
  }, [props.whatsAppMessageResults])

  const onImageChange = (e) => {

    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    setImageFile(e.target.files[0])
    reader.onload = () => {
      setImage(reader.result)
      const  fileType = e.target.files[0]['type'];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
      if (validImageTypes.includes(fileType)) {
        setIsImage(true)
      } else {
        setIsImage(false)
      }
    }
    
  }

  return (
    <div style={{marginTop: "0px", margin: "auto", maxWidth: "600px"}}>
        <DialogTitle sx={{textAlign: "center"}}>Send Report on WhatsApp</DialogTitle>
        { selectedTemplate && !selectedTemplate.name.includes("only_text") 
          && <WhatsappTemplate 
              isImage={isImage} 
              image={(props.showDefault && selectedTemplate.default_file) || image} 
              buttons={selectedTemplate && selectedTemplate.components && selectedTemplate.components.find(comp => comp.type === "BUTTONS") && selectedTemplate.components.find(comp => comp.type === "BUTTONS").buttons} 
              texts={selectedTemplate && selectedTemplate.components && selectedTemplate.components.find(comp => comp.type === "BODY").text} />}
              <p style={{textAlign: "center", fontWeight: "bold", margin: 0, margin: "10px"}}>Remaining message limit: {messageLimit}</p>
        <div className='template-select-container'>
          {/* {props.hideDropdown ? 
            <FormControl style={{width: "90%", marginLeft: "auto", marginRight: "auto"}}>
            {/* <InputLabel id="demo-simple-select-label"></InputLabel> 
              <TextField
                name="additional-texts"
                value={exchangeTexts}
                style={{width: "100%"}}
                onChange={e => setExchangeTexts(e.target.value)}
                placeholder="Free field message e.g. Hey, {name}, we have got an amazing offer for you"
                multiline
                minRows={3}
                maxRows={3}
                helperText={"Tip: Use {name} into your message to dynamically inject customer name in place of {name} tag"}
              />
            </FormControl>
          :  */}
          {!props.showDefault && <FormControl className='template-select' fullWidth>
          <InputLabel id="demo-simple-select-label">Select Template</InputLabel>
            <Select
              value={selectedTemplate}
              style={{width: "100%"}}
              onChange={(e) => setSelectedTemplate(e.target.value)}
            >{
              templateList && templateList.map(template => !ignoreList.includes(template.name) && <MenuItem value={template}>{`${template.name} (${template.language})`}</MenuItem>)
            }</Select>
          </FormControl>}
          {!props.showDefault && selectedTemplate && !selectedTemplate.name.includes("only_text") && <label class="file-upload">
              <UploadFileIcon />
              <p>{imageName || "Upload Image"}</p>
              <input type="file" onChange={onImageChange}/>
          </label>}
        </div>
        <DialogContent sx={{width: "100%", maxHeight: "300px"}} style={{overflowX: "hidden"}}>
        {selectedTemplate && (selectedTemplate.name.includes("text") || selectedTemplate.name.includes("text_with_image") || selectedTemplate?.default_text) && <>
              <label for="additional-texts"><b>Additional Texts:</b></label>
              <textarea
                name="additional-texts"
                value={props.hideDropdown ? exchangeTexts : additionTexts}
                style={{width: "100%"}}
                disabled={props.showDefault}
                onChange={(e) => props.hideDropdown ? setExchangeTexts(e.target.value) : setAdditionTexts(e.target.value)}
                placeholder="We wish you a very Happy Diwali"
                rows={5}
              />
            </>}
            {/* {
              selectedTemplate && selectedTemplate.components.some(comp => comp.type === "BUTTONS") &&
              <div style={{display: "flex", alignItems: "center", gap: "1rem"}}>
              <label for="url"><b>Enter products detail link:</b></label>
                <TextField
                  name="url"
                  id="outlined-name"
                  label="Product link"
                  style={{width: "60%"}}
                  type="text"
                  value={buttonUrl}
                  onChange={(e) => setButtonUrl(e.target.value)}
                />
              </div>
            } */}
          {props.showInputField ? 
            <>
              <br/>
              <label for="numbers"><b>Enter numbers sparated by coma:</b></label>
              <br/>
              <textarea
                name="numbers"
                inputMode={"decimal"}
                value={numbers}
                style={{width: "100%"}}
                onChange={(e) => setNumbers(e.target.value)}
                placeholder="Ex- 9876543210,8796543210"
                rows={5}
              />
            </>
         : <WhatsAppList numberList={numberList} />}
        </DialogContent>
        <DialogActions style={{display: "flex", justifyContent: "center"}}>
          <Button variant="outlined" style={{border: "1px solid rgba(25, 118, 210, 0.5)", color: "#1976d2"}} onClick={props.handleCancel}>Cancel</Button>
          <Button variant='contained' style={{backgroundColor: "#1976d2"}} onClick={() => {
            if(props.showInputField){
              numberList = numbers.split(",")
            }
            if(selectedTemplate && selectedTemplate.name.includes("text") && additionTexts.length>0){
              props.handleSend(numberList, selectedTemplate, imageFile, additionTexts, exchangeTexts)
            } else {
              props.handleSend(numberList, selectedTemplate, imageFile, "", exchangeTexts)}
            }
            } >
            {!props.isSendInProgress ? "Send" :
              <CircularProgress style={{color: "#fff", opacity: "0.7", height: "2rem", width: "2rem"}} />}
            </Button>
        </DialogActions>
      </div>
  )
}

export default WhatsAppPopup