import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthDataService from "../services/auth.services";
import Cookies from 'js-cookie'


const initialState = {
    _token: null,
    isAuthenticated: false,
    managerEmail: ''
}

export const login = createAsyncThunk(
    "login/",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.login(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const otpVerify = createAsyncThunk(
    "validate_otp/",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.otpVerify(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getSalesData = createAsyncThunk(
    "simistocks/simidata",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.getSalesData(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getStocksData = createAsyncThunk(
    "simistocks/simistocksdata",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.getStocksData(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logOut: (state) => {
            state._token = null;
            state.isAuthenticated = false;
            localStorage.removeItem("login");
            localStorage.removeItem("forbidden");
            window.location.href = "#/login";
        },
        forbidden: (state) => {
            window.location.replace("#/forbidden");
            localStorage.setItem("forbidden", true)
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            if (action.payload && action.payload.token) {
                state._token = action.payload?.token
            }
        },
        [otpVerify.fulfilled]: (state, action) => {
            if (action.payload && action.payload.token) {
                state._token = action.payload?.token
            }
        },
    },
});
export const { logOut } = authSlice.actions;
export const { forbidden } = authSlice.actions;
export default authSlice.reducer;
