import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import simiInfoIcon from "./simi-info-icon.png"
import CircularProgress from '@mui/material/CircularProgress';
import { login, otpVerify } from '../../slices/auth';
import { useDispatch } from 'react-redux';
import { toastError, toastSuccess } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export default function SignIn() {
    const [isLoginInProgress, setIsLoginInProgress] = React.useState(false)
    const [show, setShow] = React.useState(false)
    const [showFCMToken, setShowFCMToken] = React.useState(false)
    const [fcmToken, setFcmToken] = React.useState("")
    const [copied, setCopied] = React.useState(false)
    const [showOTP, setShowOTP] = React.useState(false)
    const [otp, setOTP] = React.useState("")
    const [username, setUserName] = React.useState("")
    const [userId, setUserId] = React.useState()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleOTPSubmit = (event) => {
        event.preventDefault();
        setIsLoginInProgress(true)

        const data = new FormData(event.currentTarget);
        const body = { 
          username: username,
          otp: data.get('otp'), 
          user_id: userId, 
          fcmToken: fcmToken, 
          clientType: window.cordova ? "mobile-app" : "web-app",
          deviceDetails: window.device ? {...window.device} : {}
        };
        
        dispatch(otpVerify({body}))
        .then((res) => {
          if(res?.payload?.token) {
            localStorage.setItem("login", JSON.stringify(res.payload))
            setTimeout(navigate("/"), 100)
          } else {
            setIsLoginInProgress(false)
            toastError(res.payload)
            setIsLoginInProgress(false)
          }
        })
        .catch((error) => {
        // console.log(error);
          toastError(error.message)
          setIsLoginInProgress(false)
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoginInProgress(true)

        const data = new FormData(event.currentTarget);
        const body = { 
          username: data.get('email'), 
          password: data.get('password'), 
          fcmToken: fcmToken, 
          clientType: window.cordova ? "mobile-app" : "web-app",
          deviceDetails: window.device ? {...window.device} : {}
        };
        
        dispatch(login({body}))
        .then((res) => {
          // console.log(res.payload.token, res.payload, res);
          if(res.payload.token) {
            localStorage.setItem("login", JSON.stringify(res.payload))
            setTimeout(navigate("/"), 100)
          } else if(res.payload.otp_authentication) {
            setIsLoginInProgress(false)
            toastSuccess("OTP sent to your mobile number!")
            setUserId(res.payload.user_id)
            setIsLoginInProgress(false)
            setShowOTP(true)
          } else {
            setIsLoginInProgress(false)
            toastError(res.payload.message || res.payload.non_field_errors?.[0])
            setIsLoginInProgress(false)
          }
        })
        .catch((error) => {
        // console.log(error);
          toastError(error.message)
          setIsLoginInProgress(false)
        });
    };

    React.useEffect(() => {
      localStorage.getItem("login") ? navigate("/") : setShow(true)
      window.FirebasePlugin?.getToken(token => setFcmToken(token), error => console.log("FCM Token Error", error))
    }, [])

    const getToken = () => {
      return fcmToken
    }

  return (
      show && <Container style={{
        background: "0% 0% / cover rgb(146 225 253)",
        maxWidth: "100vw", 
        maxHeight: "100vh",
        width: "100vw",
        height: "100vh",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: "cover",
        // backgroundImage: "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
       }} maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            background: "#fff",
            width: "26rem",
            padding: "1.5rem",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: "rgba(0, 0, 0, 0.87)",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            borderRadius: "12px",
            // boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
            boxShadow: "2px 2px 7px",
            // border: "3px solid #f0f0f0",
            overflow: "hidden"
          }}
        >
          <Typography component="h1" variant="h5">
            <img src={simiInfoIcon} style={{width: "9rem"}} alt="simi infotech" />   
          </Typography>
          { showOTP ? 
            <Box component="form" onSubmit={handleOTPSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                value={otp}
                onChange={e => setOTP(e.target.value)}
                id="otp"
                label="Enter OTP"
                name="otp"
                autoComplete={false}
                disabled={isLoginInProgress}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2}}
                disabled={isLoginInProgress}
              >
                {!isLoginInProgress ? "Submit" :
                <CircularProgress style={{color: "#fff", opacity: "0.7", height: "2rem", width: "2rem"}} />}
              </Button>
            </Box>
          :  
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Username"
                name="email"
                value={username}
                onChange={e => setUserName(e.target.value)}
                autoComplete="email"
                autoFocus
                disabled={isLoginInProgress}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                disabled={isLoginInProgress}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2}}
                disabled={isLoginInProgress}
              >
                {!isLoginInProgress ? "Log In" :
                <CircularProgress style={{color: "#fff", opacity: "0.7", height: "2rem", width: "2rem"}} />}
              </Button>
            </Box>
          }
          {process.env.REACT_APP_DEVICETYPE === "nativeApp" && <Typography><span onClick={() => setShowFCMToken(true)} style={{textDecoration: "underline", color: "blue", cursor: "pointer"}}>Click here</span> to see Device id for verification</Typography>}
          <Dialog sx={{textAlign: "center"}} open={showFCMToken} onClose={() => setShowFCMToken(false)}>
            <DialogTitle><b>Device ID</b> <br/> <p style={{fontSize: "11px"}}>Please copy this ID and share with admin to get access</p></DialogTitle>
            <DialogContent>
              <h3 style={{fontSize: "12px", overflowWrap: "anywhere"}}>{getToken()}</h3>
              <span style={{border: "1px solid #000", borderRadius: "4px", background: copied ? "#d2ffde" : "", padding: "4px 6px", cursor: "pointer"}} onClick={() => {navigator?.clipboard.writeText(fcmToken);setCopied(true)}}>{copied ? "Copied" : "Copy"}</span>
            </DialogContent>
          </Dialog>
        </Box>
      </Container>
  );
}